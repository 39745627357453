import React from "react";
import SocialAuthentications from "./SocialAuthentications";

export default function SignInForm(props) {

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      console.log('Enter key pressed')
    }
  }

  return (<>
      <div className="container mx-auto px-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div
              className="relative flex flex-col min-w-0 break-words w-full
            mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
              <div className="rounded-t px-6 py-6">
                <SocialAuthentications errormsg={props.errormsg}/>
                <hr className="mt-6 border-b-1 border-gray-400"/>
              </div>
              <div className="flex-auto px-4 lg:px-10 pb-10 pt-0">
                <div className="text-gray-500 text-center mb-3 font-bold">
                  <small>Sign in with credentials</small>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs
                    font-bold mb-2"
                      htmlFor="grid-username"
                    >
                      Username
                    </label>
                    <input
                      name="username"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700
                    bg-white rounded text-sm shadow focus:outline-none
                    focus:shadow-outline w-full ease-linear
                    transition-all duration-150"
                      placeholder="Username"
                      onChange={props.onChange}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold
                       mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      name="password"
                      type="password"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700
                      bg-white rounded text-sm shadow focus:outline-none
                      focus:shadow-outline w-full ease-linear transition-all
                      duration-150"
                      placeholder="Password"
                      onChange={props.onChange}
                    />
                  </div>
                  <div className="text-center mt-6">
                    <button
                      className="bg-gray-900 text-white active:bg-gray-700
                    text-sm font-bold uppercase px-6 py-3 rounded shadow
                    hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
                    w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={props.onClick}
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={props.onClick1}
                  className="text-gray-300"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <button
                  className="text-gray-300"
                  onClick={props.onClick2}
                >
                  <small>Create new account</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}