// Material UI
import {green, red} from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import React, {useEffect, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import {convertJSONtoQuestionFormat} from "../../../questionEngine/ConvertJSONtoQuestionFormat";
import {Mixpanel} from "../../../utils/marketing/Mixpanel";

// Components
import {QuestionPlatformUI} from "../components/QuestionUI/QuestionPlatformUI";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export const rightWrongTheme = createMuiTheme({
  palette: {primary: red, secondary: green},
});

export const apiName = "questionFetcher";
export const path = "/piebar";
export const myInit = {
  // OPTIONAL
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  }, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead
                  // of only response.data)
  body: {
    // OPTIONAL
    rows: 5,
    cols: 5,
    mean: 5,
    stdev: 1,
    outliers: 2,
    minQs: 1,
    types: [0],
  },
};

export default function TimedTest() {
  const {testno} = useParams();

  const practiceqs = convertJSONtoQuestionFormat(testno);
  var initialMinute = Math.round(0.75 * practiceqs.length);
  var initialSecond = 0;

  Mixpanel.track("About to begin test Paper", {
    "test": testno,
  })

  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSecond);
  const [view, handleView] = useState("begin");

  useEffect(() => {
    if (view === "questions") {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  if (isNaN(parseInt(testno))) {
    return <Redirect to="/practice"/>;
  }

  return (
    <QuestionPlatformUI
      minutes={minutes}
      seconds={seconds}
      hintAllowed={false}
      view={view}
      handleView={handleView}
      practiceqs={practiceqs}
    />
  );
}
