import React from "react";

export default function HowItWorks() {
  return (
    <div>
      <div className="container mx-auto overflow-hidden py-10">
        <div className="text-center items-center">
          <h3
            className="text-5xl mt-2 font-bold leading-normal text-casemath-blue">
            How it works
          </h3>
        </div>

        <div className="flex flex-col md:flex-row items-top">
          {/* Item 1 - top left*/}
          <div className="w-full md:w-6/12 px-12 md:px-4 ml-auto mr-auto">
            <div className="flex flex-row items-top text-left">
              <div className="ml-auto mr-12 mt-12">
                <div
                  className="text-gray-600 p-5 text-center inline-flex items-center justify-center w-20 h-20 bg-white">
                  <i
                    className="fas fa-user-plus text-4xl text-casemath-blue"/>
                </div>
              </div>
              <div className="w-full md:w-11/12 ml-auto mr-auto mt-12">
                <div
                  className="text-lg font-light leading-relaxed mb-4 mt-4 text-gray-700 text-left">
                  <span
                    className="text-xl mb-2 mt-2 font-semibold leading-normal text-left">
                    1. Sign up{" "}
                  </span>
                  and tell us what you're applying for. We'll tailor your
                  syllabus.
                </div>
              </div>
            </div>

            {/* Item 3 - bottom left */}
            <div className="flex flex-row items-top">
              <div className="ml-auto mr-12 mt-12">
                <div
                  className="text-gray-600 p-5 text-center inline-flex items-center justify-center w-20 h-20 bg-white">
                  <i
                    className="fab fa-leanpub text-4xl text-casemath-blue"/>
                </div>
              </div>
              <div className="w-full md:w-11/12 ml-auto mr-auto mt-12">
                <div
                  className="text-lg font-light leading-relaxed mb-4 mt-4 text-gray-700 text-left">
                  <span
                    className="text-xl mb-2 mt-2 font-semibold leading-normal">
                    3. Practice{" "}
                  </span>
                  unlimited questions constantly adapting around your
                  strengths and weaknesses.
                </div>
              </div>
            </div>
          </div>

          {/* Item 2 - top right */}
          <div className="w-full md:w-6/12 px-12 md:px-4 ml-auto mr-auto">
            <div className="flex flex-row items-top text-left">
              <div className="ml-auto mr-12 mt-12">
                <div
                  className="text-gray-600 text-center inline-flex items-center justify-center w-20 h-20 p-5 bg-white">
                  <i className="fas fa-chalkboard text-4xl text-casemath-blue"></i>
                </div>
              </div>
              <div className="w-full md:w-10/12 ml-auto mr-auto mt-12">
                <div
                  className="text-lg font-light leading-relaxed mb-4 mt-4 text-gray-700 text-left">
                  <span
                    className="text-xl mb-2 mt-2 font-semibold leading-normal">
                    2. Learn{" "}
                  </span>
                  from interactive lessons and coaching by successful applicants.
                </div>
              </div>
            </div>

            {/* Item 4 - bottom right*/}
            <div className="flex flex-row items-center">
              <div className="ml-auto mr-12 mt-12">
                <div
                  className="text-gray-600 p-5 items-center w-20 h-20 justify-center bg-white">
                  <i
                    className="fas fa-chalkboard-teacher text-4xl text-casemath-blue"></i>
                </div>
              </div>
              <div className="w-full md:w-11/12 ml-auto mr-auto mt-12">
                <div
                  className="text-lg font-light leading-relaxed mb-4 mt-4 text-gray-700 text-left">
                  <span
                    className="text-xl mb-2 mt-2 font-semibold leading-normal">
                    4. Test{" "}
                  </span>
                  yourself with exams and interviews and receive feedback
                  from successful applicants.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
