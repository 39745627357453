// Amplify
import {Auth} from "aws-amplify";
import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";

// views
import Settings from "views/admin/Settings.js";
import ProfilePage from "views/profile/ProfilePage";
import * as Links from "./Links";

/* -------------------------------------------------------------------------- */
/*                             User page routing                             */

/* -------------------------------------------------------------------------- */

function Content() {
  const history = useHistory()
  const [user, setUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(u => setUser(u)).catch(_ => history.replace(Links.AuthLogin))
  }, [])

  return (
    <>
      {/* Header */}
      <Switch>
        <Route path="/user/settings" exact component={Settings}/>
        <Route path="/user/profile" exact component={ProfilePage}/>
        <Redirect from="/user" to="/user/profile"/>
      </Switch>
    </>
  );
}

export default Content;
