import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignUp,
  AmplifySignIn,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import MakeQuestions from "views/admin/MakeQuestions.js";
import Editor from "views/admin/Editor";

/* -------------------------------------------------------------------------- */
/*                             Admin page routing                             */
/* -------------------------------------------------------------------------- */

function Admin() {
const [user, setUser] = useState(null);

const getUser = async () => {await Auth.currentAuthenticatedUser().then(r => setUser(r))};

// // the array of groups that the user belongs to
// user.signInUserSession.accessToken.payload["cognito:groups"]

console.log(user);



  // let groups = user ? user.signInUserSession.accessToken.payload["cognito:groups"] : null;
  // if (authState === AuthState.SignedIn && user) {
  //   if (groups != null && groups.includes("Casemath-team")) {

  if (user && user.signInUserSession.accessToken.payload["cognito:groups"].includes('admins')) {
      return (
        <>
        <AmplifyAuthenticator>
          <Sidebar />
          <div className="relative md:ml-64 bg-gray-200">
            <AdminNavbar username={user} />

            {/* Header */}
            <HeaderStats />
            <div className="px-4 md:px-10 mx-auto w-full -m-24">
              <Switch>
                <Route path="/admin/dashboard" exact component={Dashboard} />
                <Route path="/admin/maps" exact component={Maps} />
                <Route path="/admin/settings" exact component={Settings} />
                <Route path="/admin/tables" exact component={Tables} />
                <Route
                  path="/admin/makequestions"
                  exact
                  component={MakeQuestions}
                />
                <Route path="/admin/editor" exact component={Editor} />
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
              <FooterAdmin />
            </div>
          </div>
          </AmplifyAuthenticator>
        </>
      );
    } 
    else {
      return <div className="text-center items-center justify-center font-bold text-2xl flex align-center">You are unauthorised to access this resource.</div>;
    }
  }

//   const MyTheme = {
//     signInButtonIcon: { display: "none" },
//     googleSignInButton: { backgroundColor: "red", borderColor: "red" },
//   };

//   return (
//     <div>
//       <AmplifyAuthenticator theme={MyTheme}>
//         <AmplifySignUp
//           slot="sign-up"
//           formFields={[
//             { type: "username", required: true, label: "Email Address" },
//             { type: "password", required: true },
//           ]}
//         />
//       </AmplifyAuthenticator>
//     </div>
//   );
// }

export default Admin;
