import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import Canvas from "utils/effects/ParticlesCanvas";
import {Mixpanel} from "../../utils/marketing/Mixpanel";

export default function Leaderboard() {

  Mixpanel.track("Entered Leaderboard Page")

  return (
    <div className="select-none">
      <IndexNavbar fixed/>
      <div className="container mx-auto overflow-hidden py-10">
        <div className="text-center items-center">
          <Canvas className="absolute"/>
          <div className="container mx-auto overflow-hidden pb-16 pt-12 ">
            <div className="flex flex-wrap items-top text-center">
              <div className="container">
                <h3
                  className="text-4xl mb-2 mt-2 leading-normal text-casemath-blue">
                  Leaderboard - coming soon
                </h3>
                <br/>
                <table className="mx-auto table-auto">
                  <thead className="bg-casemath-blue flex text-white w-full">
                  <tr className="">
                    <th className="p-4 w-1/4">Username</th>
                    <th className="p-4 w-1/4">Accuracy</th>
                    <th className="p-4 w-1/4">Questions answered</th>
                    <th className="p-4 w-1/4">Overall improvement</th>
                  </tr>
                  </thead>
                  <tbody
                    className="bg-grey-light flex flex-col items-center
                    justify-between overflow-y-scroll w-full">
                  <tr className="flex w-full mb-4">
                    <td className="p-4 w-1/4">User 1</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                  </tr>
                  <tr className="flex w-full mb-4">
                    <td className="p-4 w-1/4">User 2</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                  </tr>
                  <tr className="flex w-full mb-4">
                    <td className="p-4 w-1/4">User 3</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                  </tr>
                  <tr className="flex w-full mb-4">
                    <td className="p-4 w-1/4">User 4</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                  </tr>
                  <tr className="flex w-full mb-4">
                    <td className="p-4 w-1/4">User 5</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                    <td className="p-4 w-1/4">---</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
    
    