/*eslint-disable*/
// components
import {Auth} from "aws-amplify";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Logo from "utils/assets/Logo";
import * as Links from "../../layouts/Links";

/* -------------------------------------------------------------------------- */
/*                         Navbar for general website                         */
/* -------------------------------------------------------------------------- */

//TODO: APIify
const NavbarLinks = [
  {
    title: "About",
    url: "/about",
  },
  {title: "Practice", url: "/practice"},
]

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setUser(user)
    })
  }, []);

  return (
    <div className="shadow-sm">
      <nav
        className="top-0 z-50 w-full flex flex-wrap items-center justify-between
         px-2 py-3 navbar-expand-lg bg-gray-200 shadow-sm">
        <div
          className="container px-12 mx-auto flex flex-wrap items-center
          justify-between">
          <div
            className="w-full relative flex justify-between lg:w-auto lg:static
            lg:block lg:justify-start items-top">
            <Link
              to="/"
              className="text-gray-800 text-xl font-bold leading-relaxed
              inline-block mr-4 py-2 whitespace-no-wrap uppercase"
            >
              <Logo/>
            </Link>

            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border
              border-solid border-transparent rounded bg-transparent block
              lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"/>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-gray-200 lg:bg-transparent " +
              "lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">{/* <IndexDropdown /> */}</li>
              <ul className="flex flex-col lg:flex-row list-none mr-auto">
                {enumerateAllNavbarLinks()}
              </ul>
              <li className="flex items-center">
                {!user ? (
                  <Link to={Links.AuthLogin}>
                    <button
                      className="bg-casemath-blue text-white active:bg-blue-600
                      text-xs font-bold uppercase px-4 py-2 rounded shadow
                      hover:shadow-lg outline-none focus:outline-none lg:mr-1
                      lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                      type="button"
                    >
                      <i className="fas fa-sign-in-alt"/> Log in
                    </button>
                  </Link>
                ) : (
                  <UserDropdown/>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

const enumerateAllNavbarLinks = () => {
  return (
    <>
      {NavbarLinks.map(l => (
        <li className="flex items-center">
          <Link
            className="hover:text-blue-600 text-casemath-blue px-3 py-4
                    lg:py-2 flex items-center text-sm uppercase font-bold"
            to={l.url}
          >
            <i className="text-gray-500 text-lg leading-lg mr-2"/>{" "}
            {l.title}
          </Link>
        </li>
      ))}
    </>
  )
}