import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Components
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {client} from "queries/client";

// Sanity IO
import {GET_TOPICS} from "queries/gettopics";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Mixpanel} from "../../../../utils/marketing/Mixpanel";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function TopicsPage() {
  const history = useHistory();
  const classes = useStyles();
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    client
      .fetch(GET_TOPICS, {})
      .then((data) => setTopics(data))
      .catch((err) => console.log("ERROR WITH GROQ REQUEST:", err));
  }, []);

  const routeChange = (url) => {
    let topic = topics.filter(t => t.url === url)
    Mixpanel.track(`Selected a topic`, {"topic": topic.title})
    history.push(`/practice/topics/${url}`);
  };

  return (
    <React.Fragment>
      <IndexNavbar fixed/>
      <CssBaseline/>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="m">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="primary"
              gutterBottom
            >
              Practice
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              Practice some tests or try out some questions below. More
              features are being added daily, so check back soon!
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => routeChange("tests")}
                  >
                    Take a test
                  </Button>
                </Grid>
              </Grid>
              {/*<Grid container spacing={2} justify="center">*/}
              {/*  <Grid item>*/}
              {/*    <Button variant="outlined" color="info">*/}
              {/*      Practice - coming soon*/}
              {/*    </Button>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {topics.map((t) => (
              <Grid item key={t.title} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={t.img}
                    title={t.title}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {t.title}
                    </Typography>
                    <Typography>{t.description}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="large"
                      color="primary"
                      onClick={() => routeChange(t.slug)}
                    >
                      Start
                    </Button>
                    {/*<Button size="small" color="secondary">
                      What's this?
                    </Button>*/}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Footer/>
      {/* End footer */}
    </React.Fragment>
  );
}
