import React from "react";

const sanityClient = require("@sanity/client");
require("dotenv").config();

export const client = sanityClient({
  projectId: process.env.REACT_APP_CMS_PROJID,
  dataset: process.env.REACT_APP_CMS_DATASET,
  // or leave blank to be anonymous user
  useCdn: true, // `false` if you want to ensure fresh data
});

export const useSanity = query => {
  const [content, setContent] = React.useState();

  React.useEffect(() => {
    client.fetch(query).then(fetchedContent => {
      setContent(fetchedContent);
    });
  }, [query]);

  return content;
};