import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import {Mixpanel} from "../../utils/marketing/Mixpanel";

// TODO this needs to be improved significantly with more detail - "the
// Service" needs to be defined

var url = window.location.origin;
var cookiePolicyUrl = window.location.origin;
export default function PrivacyPolicy() {

  Mixpanel.track("Entered Privacy Policy Page")

  return (
    <>
      <IndexNavbar fixed/>

      <section className="container relative items-center flex mx-auto my-5">
        <div className="p-5 bg-white shadow-lg rounded-xl text-lg mx-auto">
          <div className="text-center justify-center">
            <h1 className="text-3xl text-casemath-blue font-semibold">
              Privacy Policy
            </h1>
            <br/>
            <span>Last Updated On 21-Jan-2021</span>
            <br/>
            <span>Effective Date 21-Jan-2021</span>
          </div>

          <br/>
          <p className="">
            This Privacy Policy describes the policies of Casemath, Exhibition
            Road, South Kensington, London SW7 1BJ, United Kingdom of Great
            Britain and Northern Ireland (the), email: support@casemath.com,
            phone: 07900843881 on the collection, use and disclosure of your
            information that we collect when you use our website ({url}). (the
            “Service”). By accessing or using the Service, you are consenting to
            the collection, use and disclosure of your information in accordance
            with this Privacy Policy. If you do not consent to the same, please
            do not access or use the Service.
          </p>
          <br/>
          <p className="">
            We may modify this Privacy Policy at any time without any prior
            notice to you and will post the revised Privacy Policy on the
            Service. The revised Policy will be effective 180 days from when the
            revised Policy is posted in the Service and your continued access or
            use of the Service after such time will constitute your acceptance
            of the revised Privacy Policy. We therefore recommend that you
            periodically review this page.
          </p>
          <br/>
          <ol className="">
            <li>
              <h2 className="font-bold ">Information We Collect:</h2>
              <br/>
              <p className="">
                We will collect and process the following personal information
                about you:
              </p>
              <ol className="list-disc list-inside">
                <li>Name</li>

                <li>Email</li>

                <li>Date of Birth</li>

                <li>Payment information</li>

                <li>Casemath usage statistics</li>
              </ol>
            </li>
            <br/>
            <li>
              <h2 className="font-bold ">How We Collect Your Information:</h2>
              <p className="">
                We collect/receive information about you in the following
                manner:
              </p>
              <ol className="list-disc list-inside">
                <li>
                  Upon filling out registration forms or other means of
                  submitting personal information
                </li>
                <li>Interactions with the website</li>

                <li>From public sources</li>
              </ol>
            </li>
            <br/>
            <p className="how-we-use-info-detailed">
              We will retain and evaluate information on your recent visits to
              our website and how you move around different sections of our
              website for analytics purposes to understand how people use our
              website so that we can make it more intuitive. We will keep a
              record of the articles on our website that you have clicked on and
              use that information to target advertising on this website to you
              that is relevant to your interests, which we have identified based
              on articles you have read. We will keep a record of the lessons,
              questions and tests you complete to determine your individual
              strengths and weaknesses, allowing us to tailor content
              recommended to you to maximally benefit you.
            </p>
            <br/>
            <li>
              <h2 className="font-bold ">How We Use Your Information:</h2>
              <p className="">
                We will use the information that we collect about you for the
                following purposes:
              </p>
              <ol className="list-disc list-inside">
                <li>Marketing/Promotional</li>

                <li>Creating user account</li>

                <li>Testimonials</li>

                <li>Customer feedback collection</li>

                <li>Enforcing terms and conditions</li>

                <li>Processing payment</li>

                <li>Support</li>

                <li>Administration info</li>

                <li>Targeted advertising</li>

                <li>Manage customer order</li>

                <li>Site protection</li>

                <li>User to user comments</li>

                <li>Dispute resolution</li>

                <li>Manage user account</li>
              </ol>
              <p className="">
                If we want to use your information for any other purpose, we
                will ask you for consent and will use your information only on
                receiving your consent and then, only for the purpose(s) for
                which grant consent unless we are required to do otherwise by
                law.
              </p>
            </li>
            <br/>
            <li>
              <h2 className="font-bold ">How We Share Your Information:</h2>
              <br/>
              <p className="">
                We will not transfer your personal information to any third
                party without seeking your consent, except in limited
                circumstances as described below:
              </p>
              <ol className="list-disc list-inside">
                <li>Ad service</li>

                <li>Sponsors</li>

                <li>Marketing agencies</li>

                <li>Legal entities</li>

                <li>Analytics</li>

                <li>Payment recovery services</li>

                <li>Data collection & processing</li>
              </ol>
              <br/>
              <p className="">
                We require such third parties to use the personal information we
                transfer to them only for the purpose for which it was
                transferred and not to retain it for longer than is required for
                fulfilling the said purpose.
              </p>
              <p className="">
                We may also disclose your personal information for the
                following: (1) to comply with applicable law, regulation, court
                order or other legal process; (2) to enforce your agreements
                with us, including this Privacy Policy; or (3) to respond to
                claims that your use of the Service violates any third-party
                rights. If the Service or our company is merged or acquired with
                another company, your information will be one of the assets that
                is transferred to the new owner.
              </p>
            </li>
            <br/>
            <li>
              <h2 className="font-bold ">Retention Of Your Information:</h2>
              <p className="">
                We will retain your personal information with us for 90 days to
                2 years after users terminate their accounts or for as long as
                we need it to fulfill the purposes for which it was collected as
                detailed in this Privacy Policy. We may need to retain certain
                information for longer periods such as record-keeping /
                reporting in accordance with applicable law or for other
                legitimate reasons like enforcement of legal rights, fraud
                prevention, etc. Residual anonymous information and aggregate
                information, neither of which identifies you (directly or
                indirectly), may be stored indefinitely.
              </p>
            </li>
            <br/>
            <li>
              <h2 className="font-bold ">Your Rights:</h2>
              <p className="">
                Depending on the law that applies, you may have a right to
                access and rectify or erase your personal data or receive a copy
                of your personal data, restrict or object to the active
                processing of your data, ask us to share (port) your personal
                information to another entity, withdraw any consent you provided
                to us to process your data, a right to lodge a complaint with a
                statutory authority and such other rights as may be relevant
                under applicable laws. To exercise these rights, you can write
                to us at support@casemath.com. We will respond to your request
                in accordance with applicable law.
              </p>
              <br/>
              <p className="">
                Do note that if you do not allow us to collect or process the
                required personal information or withdraw the consent to process
                the same for the required purposes, you may not be able to
                access or use the services for which your information was
                sought.
              </p>
            </li>
            <li>
              <h2 className="font-bold ">Cookies Etc.</h2>
              <p className="">
                To learn more about how we use these and your choices in
                relation to these tracking technologies, please refer to our
                <a href={cookiePolicyUrl}>Cookie Policy.</a>
              </p>
            </li>
            <br/>
            <li>
              <h2 className="font-bold ">Security:</h2>
              <p className="">
                The security of your information is important to us and we will
                use reasonable security measures to prevent the loss, misuse or
                unauthorized alteration of your information under our control.
                However, given the inherent risks, we cannot guarantee absolute
                security and consequently, we cannot ensure or warrant the
                security of any information you transmit to us and you do so at
                your own risk.
              </p>
            </li>
            <br/>
            <li>
              <h2 className="font-bold ">Grievance/Data Protection Officer:</h2>
              <p className="">
                If you have any queries or concerns about the processing of your
                information that is available with us, you may email our
                Grievance Officer at Casemath, Exhibition Road, South
                Kensington, email: support@casemath.com. We will address your
                concerns in accordance with applicable law.
              </p>
            </li>
          </ol>
        </div>
      </section>
      <Footer/>
    </>
  );
}
