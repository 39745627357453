import React from "react";
const Links = require("../../layouts/Links");

/* -------------------------------------------------------------------------- */
/*             Footer during authentication and other small pages             */
/* -------------------------------------------------------------------------- */

export default function FooterSmall(props) {
  return (
    <>
      <footer
        className={
          (props.absolute
            ? "absolute w-full bottom-0 bg-gray-900"
            : "relative") + " pb-6"
        }
      >
        <div className="container mx-auto px-4">
          <hr className="mb-6 border-b-1 border-gray-700" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4">
              <div className="text-sm text-gray-600 font-semibold py-1 text-center md:text-left">
                Copyright © {new Date().getFullYear()}{" "}
                <a
                  href={Links.Home}
                  className="text-white hover:text-gray-400 text-sm font-semibold py-1"
                >
                  Casemath
                </a>
              </div>
            </div>
            <div className="w-full md:w-8/12 px-4">
              <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                <li>
                  <a
                    href={Links.Home}
                    className="text-white hover:text-gray-400 text-sm font-semibold block py-1 px-3"
                  >
                    Casemath
                  </a>
                </li>
                <li>
                  <a
                    href={Links.AboutUs}
                    className="text-white hover:text-gray-400 text-sm font-semibold block py-1 px-3"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href={Links.Blog}
                    className="text-white hover:text-gray-400 text-sm font-semibold block py-1 px-3"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href={Links.Copyright}
                    className="text-white hover:text-gray-400 text-sm font-semibold block py-1 px-3"
                  >
                    Copyright
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
