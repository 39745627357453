import React from "react";
import Logo from "../../../utils/assets/Logo";

export default function SocialAuthentications(props) {
  return (
    <div>
      <div className="text-center mb-3">
        {props.errormsg && (
          <h6 className="text-red-500 text-md font-bold">
            {" "}
            Error! {props.errormsg}{" "}
          </h6>
        )}
        <h6 className="text-gray-600 text-sm font-bold">
          {/*Sign in with*/}
        </h6>
      </div>
      <div
        className="text-center w-full justify-center content-center flex-auto inline-block mx-auto my-auto">
        <Logo />
        {/*<button*/}
        {/*    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"*/}
        {/*    type="button"*/}
        {/*    disabled={"disabled"}*/}
        {/*>*/}
        {/*    <img*/}
        {/*        alt="..."*/}
        {/*        className="w-5 mr-1"*/}
        {/*        src={require("assets/img/facebook.svg")}*/}
        {/*    />*/}
        {/*    Facebook*/}
        {/*</button>*/}
        {/*<button*/}
        {/*    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"*/}
        {/*    type="button"*/}
        {/*    disabled={"disabled"}*/}
        {/*>*/}
        {/*    <img*/}
        {/*        alt="..."*/}
        {/*        className="w-5 mr-1"*/}
        {/*        src={require("assets/img/google.svg")}*/}
        {/*    />*/}
        {/*    Google*/}
        {/*</button>*/}

        {/*<p className={"text-gray-600 text-xs font-bold"}>Social Authentications*/}
        {/*  Coming Soon</p>*/}
      </div>
      <br/>
    </div>)
}
