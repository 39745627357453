import React from "react";

// TODO: APIify
const azmatimg = "team-pic-azmat";
const azmatintros = [
  "Maths at Imperial College London",
  "Experience at Morgan Stanley and BAML",
  "Incoming consultant",
];

const fiyaimg = "team-pic-fiya";
const fiyaintros = [
  "Civil Engineering at Imperial College London",
  "Experience at Goldman Sachs",
  "Incoming consultant",
];

const shashwatimg = "team-pic-shashwat";
const shashwatintros = [
  "Computing at Imperial College London",
  "Experience at IBM",
  "Incoming intern at Google",
];

export default function AboutUs() {
  return (
    <div className="select-none">
      <div
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div
          className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2
            className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-casemath-blue sm:text-4xl md:mx-auto">
            <span className="relative inline-block text-casemath-blue">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7"/>
                  </pattern>
                </defs>
                <rect
                  fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative ">Meet the team</span>
            </span>{" "}
          </h2>
          {/*} <p className="text-base text-gray-700 md:text-lg">
           Sed ut perspiciatis unde omnis iste natus error sit voluptatem
           accusantium doloremque rem aperiam, eaque ipsa quae.
           </p> */}
        </div>
        {/* Next stuff */}
        <Team/>
      </div>
    </div>
  );
}

const Team = () => {
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div
        className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-3 lg:max-w-screen-lg">
        <UserProfile
          name="Azmat Habibullah"
          role="Founder"
          img={azmatimg}
          intros={azmatintros}
        />
        <UserProfile
          name="Fiya Shah"
          role="Founder"
          img={fiyaimg}
          intros={fiyaintros}
        />
        <UserProfile
          name="Shashwat Kansal"
          role="Founder"
          img={shashwatimg}
          intros={shashwatintros}
        />
      </div>
    </div>
  );
};

function UserProfile({name, role, img, intros}) {
  return (
    <div>
      <img
        className="object-cover w-40 h-40 rounded-full shadow-xl"
        src={require(`assets/img/${img}.jpg`)}
        alt="Founder Photo"
      />
      <div className="flex flex-col justify-center mt-2">
        <p className="text-lg font-bold">{name}</p>
        <p className="mb-4 text-xs text-gray-800">{role}</p>
        <p className="text-sm tracking-wide text-gray-800">
          <ul>
            {intros.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}
