/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:cb9355d8-8365-4371-a483-1364fb767e6a",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_uT4Xg3t3B",
    "aws_user_pools_web_client_id": "4s77vs755n4gn0r5hu1ivkqdd4",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://rivj6riz7va4lol2dkizeddmta.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_bots": "enable",
    "aws_bots_config": [
        {
            "name": "ScheduleAppointment_main",
            "alias": "$LATEST",
            "region": "eu-west-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "users-main",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
