/*eslint-disable*/
import Footer from "components/Footers/Footer";

// Components
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";

import {scroller} from "react-scroll";
import {Mixpanel} from "../../utils/marketing/Mixpanel";
import CallToAction from "./components/CallToAction";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";
import PracticeDemo from "./components/PracticeDemo";
import ThreePointSell from "./components/ThreePointSell";

/* ----------------- Sloped section divider for landing page ---------------- */

const SlopeBorder = (
  <div
    className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style={{transform: "translateZ(0)"}}
  >
    <svg
      className="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        className="text-gray-200 fill-current"
        points="2560 0 2560 100 0 100"
      />
    </svg>
  </div>
);

/* -------------------------------------------------------------------------- */
/*                   Compilation of Landing Page Components                   */

/* -------------------------------------------------------------------------- */

function LandingPage() {
  const formRef = React.useRef(null);

  Mixpanel.track("Entered Home Page")

  const scrollToSection = () => {
    scroller.scrollTo("sign-up-form-ref", {
      duration: 800,
      delay: 0,

      smooth: "formcontainer",
    });
  };

  return (
    <div className="select-none">
      <IndexNavbar fixed/>
      <section className="h-screen py-5 md:py-10 relative bg-white">
        <Hero handleScrollToForm={scrollToSection}/>
      </section>

      {/* <Chatbot /> */}
      {SlopeBorder}
      <section className=" relative bg-gray-100">
        <ThreePointSell/>
      </section>
      <section className="pb-10 relative bg-white">
        <HowItWorks/>
      </section>
      <section className="pb-10 relative bg-gray-100">
        <PracticeDemo/>
      </section>
      <section className="bg-white">
        <CallToAction/>
      </section>
      {/*<SubjectsShowOff /> */}
      {/* <DemoModal /> */}
      {/* <Element name="formcontainer"> */}
      {/* <section className="bg-gray-200 overflow-hidden py-20"> */}
      {/* <Form /> */}
      {/* </section> */}
      {/* </Element> */}

      {/* <section className="pb-16 bg-gray-200 relative pt-32 sign-up-form-ref z-0">
       <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20">
       <svg
       className="absolute bottom-0 overflow-hidden z-0"
       xmlns="http://www.w3.org/2000/svg"
       preserveAspectRatio="none"
       version="1.1"
       viewBox="0 0 2560 100"
       x="0"
       y="0"
       >
       <polygon
       className="text-gray-200 fill-current z-0"
       points="2560 0 2560 100 0 100"
       ></polygon>
       </svg>
       </div>
       <SupportedBy />
       </section> */}
      <Footer/>
    </div>
  );
}

export default LandingPage;
