import { createMuiTheme } from "@material-ui/core/styles";


export const casemathTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(90, 169, 189, 1)',
      contrastText: '#fff',
    },
    info: {
      main: 'rgba(102, 209, 219, 10)', // todo this isn't working
    }
  },
});
