import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify"
import "./ProfileForm.css";
import {getUser} from "../../../../queries/users/getUser";
import {updateUser} from "../../../../queries/users/updateUser";


export default function ProfileForm() {
    const initialFormState = {
        id: "",
        email: "",
        username: "",
        firstname: "",
        lastname: "",
        dob: "",
        university: "",
        city: "",
        country: "",
        interestedroles: "",
        levelofrole: "",
    };
    const [formstate, updateFormstate] = useState(initialFormState);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        // initializeForm()
        Auth.currentAuthenticatedUser().then(user => {
            updateFormstate(formstate => ({...formstate, id: user.attributes.email}));
            API.graphql({
                query: getUser,
                variables: {id: user.attributes.email}
            }).then(u => u.data["getUser"]).then(info => {
                /* Add new state values of a user from API */
                Object.keys(info).forEach((k) => {
                    if (info[k]) {
                        updateFormstate(formstate => ({...formstate, [k]: info[k]}));
                    }
                })
            })
        })
    }, [])

    function onChange(e) {
        e.persist();
        updateFormstate(() => ({...formstate, [e.target.name]: e.target.value}));
    }

    async function submitForm() {
        const newUser = await API.graphql({
            query: updateUser,
            variables: {input: formstate},
        });
        console.log("Sent!")
        setSubmitted(true)
    }

    return (
        <div>
            <h6 className="heading-small text-muted mb-4">User information</h6>
            <div className="pl-lg-4">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group focused">
                            <label
                                className="form-control-label label"
                                htmlFor="input-username"
                            >
                                Username
                            </label>
                            <input
                                value={formstate.username}
                                name="username"
                                type="text"
                                className="form-control form-control-alternative input"
                                placeholder="Username"
                                onChange={onChange}
                                disabled="disabled"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label className="form-control-label label" htmlFor="input-email">
                                Email address
                            </label>
                            <input
                                value={formstate.email}
                                type="email"
                                name="email"
                                className="form-control form-control-alternative input"
                                placeholder="rogers@gmail.com"
                                onChange={onChange}
                                disabled="disabled"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="form-group focused">
                            <label
                                className="form-control-label label"
                                htmlFor="input-first-name"
                            >
                                First name
                            </label>
                            <input
                                value={formstate.firstname}
                                name="firstname"
                                type="text"
                                className="form-control form-control-alternative input"
                                placeholder="First name"
                                onChange={onChange}
                                disabled="disabled"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group focused">
                            <label
                                className="form-control-label label"
                                htmlFor="input-last-name"
                            >
                                Last name
                            </label>
                            <input
                                value={formstate.lastname}
                                name="lastname"
                                type="text"
                                className="form-control form-control-alternative input"
                                placeholder="Last name"
                                onChange={onChange}
                                disabled="disabled"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group focused">
                            <label className="form-control-label label" htmlFor="input-dob">
                                Date of birth
                            </label>
                            <input
                                value={formstate.dob}
                                name="dob"
                                type="date"
                                className="form-control form-control-alternative input"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="my-4 hr"/>

            {/* Address */}
            <h6 className="heading-small text-muted mb-4 h6">Contact information</h6>
            <div className="pl-lg-4">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="form-group focused">
                            <label className="form-control-label" htmlFor="input-university">
                                University
                            </label>
                            <input
                                value={formstate.university}
                                type="text"
                                name="university"
                                className="form-control form-control-alternative input"
                                placeholder="University"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group focused">
                            <label className="form-control-label" htmlFor="input-city">
                                City
                            </label>
                            <input
                                value={formstate.city}
                                name="city"
                                type="text"
                                className="form-control form-control-alternative input"
                                placeholder="City"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group focused">
                            <label className="form-control-label" htmlFor="input-country">
                                Country
                            </label>
                            <input
                                value={formstate.country}
                                name="country"
                                type="text"
                                className="form-control form-control-alternative input"
                                placeholder="United Kingdom"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="my-4"/>

            {/* Description */}
            <h6 className="heading-small text-muted mb-4 h6">
                The following questions will help us tailor our content for you
            </h6>
            <div className="pl-lg-4">
                <div className="form-group focused">
                    <label className="form-control-label" htmlFor="input-level">
                        What level of role are you applying for (internship, graduate
                        scheme, etc)?
                    </label>
                    <textarea
                        value={formstate.interestedroles}
                        name="interestedroles"
                        rows={2}
                        className="form-control form-control-alternative textarea input"
                        placeholder="Tell us what applications you're focusing on!"
                        onChange={onChange}
                    />
                </div>

                <div className="form-group focused">
                    <label className="form-control-label" htmlFor="input-roles">
                        What roles are you interested in?
                    </label>
                    <textarea
                        value={formstate.levelofrole}
                        name="levelofrole"
                        rows={2}
                        className="form-control form-control-alternative textarea input"
                        placeholder="Tell us what applications you're focusing on!"
                        onChange={onChange}
                    />
                </div>
                <div>
                    {submitted &&
                    <div className={"text-casemath-blue text-xl text-left text-semibold"}> You're all set! Thanks for
                        submitting! </div>}
                    <br/>
                    <button
                        type="submit"
                        className="bg-casemath-blue text-white active:bg-gray-100
                        font-normal px-4 py-2 rounded outline-none
                        focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex
                        items-center font-bold text-xs ease-linear transition-all duration-150"
                        onClick={() => submitForm()}>
                        Submit Form
                    </button>

                </div>
            </div>
        </div>
    );
}
