// React
import API from "@aws-amplify/api";

// CSS Styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

/* CONFIGURATIONS */
import Amplify from "aws-amplify";
import "index.css";

import Routing from "layouts/Routing";
import React from "react";
import ReactDOM from "react-dom";
import awsconfig from "./aws-exports";

// Initializers
var mixpanel = require('mixpanel-browser');
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {"api_host": "https://api-eu.mixpanel.com"}, "");
Amplify.configure(awsconfig);
API.configure();

// Environment Variables
require("dotenv").config();

ReactDOM.render(<Routing/>, document.getElementById("root"))

