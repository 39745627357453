import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/core/styles";
import React from "react";
import {rightWrongTheme} from "../../../Tests/PracticePage";

export function headerView(props, view, practiceqs, setquestiono, questiono, choices) {
  return () => {
    var minutes = props.minutes;
    var seconds = props.seconds;

    function QuestionButtons() {
      return <div
        className="w-full relative flex flex-wrap justify-between lg:w-auto
        lg:static lg:block lg:justify-start pr-1">
        <div
          className="text-gray-800 text-sm font-bold leading-relaxed flex
          flex-wrap py-2 whitespace-no-wrap uppercase">
          <ButtonGroup
            size="small"
            color="primary"
            aria-label="question options"
          >
            {view !== "answers" ? (
              <div>
                {practiceqs.map((r, index) => (
                  <Button
                    onClick={() => setquestiono(index)}
                    variant={index === questiono ? "contained" : "outlined"}
                    color="primary"
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  onClick={() => setquestiono(practiceqs.length)}
                  variant="outlined"
                  color="secondary"
                >
                  End
                </Button>
              </div>
            ) : (
              <div>
                {practiceqs && (
                  <MuiThemeProvider theme={rightWrongTheme}>
                    {practiceqs.map((r, index) => (
                      <Button
                        onClick={() => setquestiono(index)}
                        variant={index === questiono ? "contained" : "outlined"}
                        color={practiceqs[index].answer === choices[index]
                          ? "secondary"
                          : "primary"}
                      >
                        {index + 1}
                      </Button>
                    ))}
                  </MuiThemeProvider>
                )}
              </div>
            )}
          </ButtonGroup>
        </div>
      </div>;
    }

    function TimeAndButtonView() {
      return <div
        className={"lg:flex flex-grow items-center lg:bg-transparent " +
        "lg:shadow-none block mx-auto pl-1"}
        id="example-navbar-warning"
      >
        <ul className="flex flex-col lg:flex-row list-none lg:mx-auto">
          {view === "questions" && (
            <li className="flex items-center">
              <div>
                <h1
                  className="text-center font-bold text-4xl text-white
                  items-center justify-center">
                  {" "}
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </h1>
              </div>
            </li>
          )}
          {view === "answers" && questiono !== practiceqs.length && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setquestiono(practiceqs.length)}>
              Results
            </Button>
          )}
        </ul>
      </div>;
    }

    return (
      <nav className="top-0 z-50
                mx-auto my-auto navbar-expand-lg bg-gray-800 shadow-xl
                text-white">
        <div className="container flex w-full">
          <QuestionButtons/>
          <TimeAndButtonView/>
        </div>
      </nav>
    );
  };
}
