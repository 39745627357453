export const unprocessedData = [
  {
    question: "How many Bavaria cars were sold in September?",
    options: ["11", "3,000", "11,000", "3"],
    answer: "B",
    hints: [
      "The trick to this question is to recognise that the graph gives sales figures cumulatively. This is a lesson in looking carefully at the graph\u2019s title and units.",
      "Step 1 - We see from the graph that the cumulative Bavaria sales at the end of August are 8,000, and at the end of September are 11,000.",
      "Step 2 - Therefore during September (11,000 \u2013 8,000 =) 3,000 Bavaria cars must have been sold.",
    ],
    picture: "0.png",
  },
  {
    question:
      "What were the total sales of Taksi cars for September, October and November combined?",
    options: ["19,000", "12,000", "8,000", "7,000"],
    answer: "D",
    hints: [
      "Don\u2019t waste time working out sales for each month. Since the data is cumulative, the combined sales for September, October and November = (cumulative sales for end of November) \u2013 (cumulative sales end of August). Remember the numbers in the graph are cumulative.",
      "Step 1 - (Cumulative sales November) \u2013 (Cumulative sales August) = (19,000) \u2013 (12,000) = 7,000",
    ],
    picture: "0.png",
  },
  {
    question:
      "If the number of Bavaria cars sold in November had been equal to the number of Bavaria cars sold in October, how many more Bavaria cars would have been sold during November?",
    options: ["1,000", "1", "12,000", "2,000"],
    answer: "A",
    hints: [
      "Step 1 - First, find out the number of Bavaria cars sold in October. From the cumulative graph we see this is 1,000.",
      "Step 2 - Now, compare this with how many Bavaria cars were actually sold in November. We see from the graph there were no sales 1,000 \u2013 0 = 1,000",
    ],
    picture: "0.png",
  },
  {
    question:
      "In Houston what was the net effect of live birth and death rates on the population during 2019 to the nearest integer?",
    options: ["16,697 decrease", "14,463 increase", "16,697 increase", "14,463 increase"],
    answer: "C",
    hints: [
      "Step 1 - In Houston the population was 2,693,000 at the start of the year. We are told there were 13.2 births per thousand of the population. So this means there were 2,693 x 13.2 = 35,547.6.",
      "Step 2 - We are told there were 12.4 deaths per thousand of the population, i.e. 2,693 x 7 = 18,851.",
      "Step 3 - The net effect on population is 6,700 \u2013 6,200 = 16,697 (rounding to nearest integer)",
      "Step 4 - Alternatively, you can calculate the net rate and multiply by 2,693, ie 2,693 x (13.2 - 7) = 16,697"
    ],
    picture: "1.png",
  },
  {
    question:
      "How many live births occurred in 2019 in Los Angeles and San Diego combined to the nearest integer?",
    options: ["64,456", "19,214", "74,167", "54,112"],
    answer: "A",
    hints: [
      "Step 1 - In Los Angeles there were 3,979 x 12 = 47,748 births. In San Diego there were 1,547 x 10.8 = 16,707.6 live births. In total that is 47,748 + 16,707.6 = 64,456 (to the nearest integer)",
    ],
    picture: "1.png",
  },
  {
    question:
      "Of the cities shown, which had the lowest number of people under the age of 15 at the start of the year 2019?",
    options: ["Los Angeles", "San Diego", "Houston", "Dallas"],
    answer: "D",
    hints: [
      "Tip - The question says \u201cof the cities shown\u201d. Without this technically we would have to respond \u201ccannot say\u201d because we are not told any information about any other cities and therefore we would not be able to say with any certainty which had the lowest number. As it happens in this question \u201ccannot say\u201d is not an option so we would have been OK, but it\u2019s a catch worth looking out for.",
      "Step 1 - Work through each city shown calculating the number of under 15 year olds. Don\u2019t worry about entering the thousands in your calculator \u2013 this just wastes time.",
      "New York: don\u2019t bother calculating as it is not a possible answer. Los Angeles: 3,979 x 0.18 = 716.2 San Diego: 1,547 x 0.21 = 324.9 Houston: 2,693 x 0.22 = 592.5 Dallas: 1,343 x 0.23 = 308.9",
    ],
    picture: "1.png",
  },
  {
    question:
      "Which of the countries shown experienced the largest percentage increase in public transport spending from 2009 to 2010?",
    options: ["Japan", "Australasia", "Russia", "Cannot tell"],
    answer: "B",
    hints: [
      "Step 1 - Calculate the percentage increase from 2009 to 2010 for each country. Don\u2019t bother with the billions, the percentage calculation won\u2019t be affected.",
      "No need to bother with Japan and North America as they are decreases. Russia: 185 \u00f7 180 = 2.777.% increase Australasia: 258 \u00f7 251 = 2.788% increase",
    ],
    picture: "2.png",
  },
  {
    question:
      "Which of the countries shown had the highest public transport spend per capita in 2009?",
    options: ["Australasia", "North America", "Russia", "Cannot say"],
    answer: "A",
    hints: [
      "Step 1 - Simply divide the public transport spend by the population for each country. Again, use units which simplify the calculation because we are only interested in the relative order of magnitude.",
      "North America: 120 \u00f7 343.287 = 0.349 Russia: 180 \u00f7 142.8 = 1.26 Australasia: 251 \u00f7 38.909 = 6.451 Japan: don\u2019t bother as this is not an option.",
      "Note that the answer to this question can be seen without doing any calculations!"
    ],
    picture: "2.png",
  },
  {
    question:
      "In 2009 Italy had a target to spend 8% more on public transport than they did in 2008. By how much were they short of this target?",
    options: [
      "\u00242.76 million",
      "\u00242.76 billion",
      "\u00242.41 billion",
      "\u00242.41 million",
    ],
    answer: "B",
    hints: [
      "Step 1 - In 2008 Italy spent \u002472 billion. An increase of 8% is: \u002472 billion x 1.08 = \u002477.76 billion.",
      "Step 2 - We see from the table that Italy actually spent \u002475 billion. That\u2019s \u00242.76 billion short of the target.",
    ],
    picture: "2.png",
  },
  {
    question:
      "Visitors arriving from which website were most likely to make a purchase at vintagejackets.com?",
    options: ["Google", "Bing", "Facebook", "Instagram"],
    answer: "A",
    hints: [
      "Step 1 - For each arrival website, calculate the percentage of visitors who made a purchase out of the number of visitors.",
      "Google: 4,681 \u00f7 416,851 = 1.12% Bing: 1,345 \u00f7 103,851 = 1.30% Facebook: 191 \u00f7 74,192 = 0.26% Instagram: 331\u00f7 56,123 = 0.59%",
      "Note you could save time by not calculating Facebook and Instagram since they are less than 1%."
    ],
    picture: "3.png",
  },
  {
    question:
      "If the average profit made per sale at vintagejackets.com was \u00a38, approximately how much more profit was made from visitors from Google than visitors from Bing?",
    options: ["\u00a326,688", "\u00a324,581", "\u00a329,392", "\u00a328,583"],
    answer: "A",
    hints: [
      "Step 1 - Calculate how many more sales came from Google than from Bing. 4,681 \u2013 1,345 = 3,336.",
      "Step 2 - Calculate the profit difference. 3,336 x \u00a38 = \u00a326,688",
    ],
    picture: "3.png",
  },
  {
    question:
      "Assuming all visitors arrived via either Google, Bing, Facebook or Instagram, approximately what percentage of visitors arrived at vintagejackets.com from Bing?",
    options: ["15.76%", "15.95%", "15.98%", "16.01%"],
    answer: "B",
    hints: [
      "Step 1 - Add up the total number of visitors. 416,851 + 103,851 + 74,192 + 56,123 = 651,017.",
      "Step 2 - Calculate the percentage of them who came from Bing. 103,851 \u00f7 651,017 = 15.95%",
    ],
    picture: "3.png",
  },
  {
    question:
      'You want to sign up with the mobile provider with the lowest "Monthly bill" to "Payment options" ratio. Which mobile provider do you select?',
    options: [
      "E-tech",
      "Four",
      "Airtec",
      "Electro",
      "Star Services",
      "None of the above",
    ],
    answer: "C",
    hints: [
      'Step 1 \u2013 Calculate the \u201cMonthly Bill" to "Payment Options" ratio for each mobile provider and select the lowest',
      "E-tech = \u00a328.55 / 7 = \u00a34.08 Four = \u00a322.95 / 5 = \u00a34.59 Airtec = \u00a321 / 7 = \u00a33 Star Services = \u00a340 / 8 = \u00a35 Electro = \u00a327.50 / 8 = 3.44",
    ],
    picture: "16.png",
  },
  {
    question:
      'You select the three mobile providers with the highest rating for "Customer Service". From these providers, you select the two with the highest rating for "Eco-friendliness". You then select the provider with the lowest "Monthly Bill", which provider do you select?',
    options: [
      "E-tech",
      "Four",
      "Airtec",
      "Star Services",
      "Electro",
      "None of the above",
    ],
    answer: "B",
    hints: [
      "Step 1 \u2013 Select the three mobile providers with the highest rating for \u201cCustomer Service\u201d: E-tech, Four and Star Services",
      "Step 2 \u2013 Of the applicable mobile providers, select the two mobile providers with the highest ratings for \u201cEco-friendliness\u201d: Star Services and Four",
      "Step 3 \u2013 Of the applicable mobile providers, select the provider with the lowest \u201cMonthly Bill\u201d: Four",
    ],
    picture: "16.png",
  },
  {
    question:
      'You only want to sign up with an mobile provider with a rating above 8 for both "Payment options" and "Network coverage". Which provider do you choose?',
    options: [
      "None of the below",
      "Four",
      "Airtec",
      "Star Services",
      "Electro",
      "None of the above",
    ],
    answer: "A",
    hints: [
      "Only E-tech has a network coverage above 8, but its payment options is only 7",
    ],
    picture: "16.png",
  },
  {
    question:
      'You select the mobile providers with "Network coverage" rating above 5. Of these providers, you select the provider with a "Customer Service" rating of above 5. You then choose this provider and sign up for one year. How much would this cost?',
    options: [
      "\u00a3480.00",
      "\u00a3370.00",
      "\u00a3575.00",
      "\u00a3440.00",
      "\u00a3419.00",
      "None of the above",
    ],
    answer: "A",
    hints: [
      "Step 1 Note there is only one provider with above 5 customer service: Star Services",
      "Step 2 \u2013 Calculate how much it would cost to sign up with this provider for 1 year: \u00a340 x 12 = \u00a3480",
    ],
    picture: "16.png",
  },
  {
    question:
      'You want to sign up with the mobile provider with the lowest "Monthly Billing" to overall average rating ratio across all features. Which provider do you select?',
    options: [
      "E-tech",
      "Four",
      "Airtec",
      "Star Services",
      "Electro",
      "None of the above",
    ],
    answer: "C",
    hints: [
      "Step 1 \u2013 Calculate the overall average rating of each mobile provider across all four features",
      "E-tech = (5 + 3 + 10 + 7) / 4 = 6.25 Four = (5 + 5 + 5 + 5) / 4 = 5 Airtec = (4 + 8 + 6 + 7) / 4 = 6.25 Star Services = (8 + 7 + 8 + 8 ) / 4 = 7.75 Electro = (4 + 6 + 7 + 8) / 4 = 6.25",
      'Step 2 \u2013 Calculate the "Monthly Billing" to overall average rating ratio for each provider and select the provider with the lowest ratio',
      "E-tech = \u00a328.55 / 6.25 = \u00a4.57 Four = \u00a322.95 / 5 = \u00a4.59 Airtec = \u00a321.00 / 6.25 = \u00a3.36 Star Services = \u00a340.00 / 7.75 = \u00a5.16 Electro = \u00a327.50 / 6.25 = \u00a4.40",
    ],
    picture: "16.png",
  },
  {
    question:
      'You want to sign up with the mobile provider with the highest rating for "Eco- friendliness" you can get while still having no ratings below 5. Which provider do you choose?',
    options: [
      "E-tech",
      "Four",
      "Star Services",
      "Electro",
      "Airtec",
      "None of the above",
    ],
    answer: "C",
    hints: [
      "Step 1 \u2013 Identify the mobile providers with no ratings below 5: Four and Star Services",
      "Step 2 \u2013 Of these, select the provider with the highest rating for \u201cEco- Friendliness\u201d: Star Services"
    ],
    picture: "16.png",
  },
  {
    question:
      'You don\'t want to sign up to a provider with any rating below 5. Which providers can you sign up with?',
    options: [
      "E-tech & Four",
      "Airtec & Star Services",
      "Four & Airtec",
      "Star Services & Four",
      "Electro & Star Services",
      "None of the above",
    ],
    answer: "D",
    hints: [
      "Step 1 \u2013 Identify the providers with all ratings equal to or greater than 5: Four and Star Services"
    ],
    picture: "16.png",
  },
  {
    question:
      'You either want to sign up to an mobile provider with a combined "Eco-friendliness" and "Network coverage" rating of 16 or higher, or a provider with a "Monthly Bill" to "Customer Service" ratio of \u00a34 or lower. Which providers can you sign up with?',
    options: [
      "None of the below",
      "Four",
      "Airtec",
      "Star Services",
      "Electro",
      "E-tech",
    ],
    answer: "A",
    hints: [
      "Step 1 \u2013 Calculate the combined \u201cCustomer Service\u201d and \u201cNetwork coverage\u201d ratings of each provider: none are above 15",
      "Step 2 \u2013Identify a provider with a \u201cMonthly Bill\u201d to \u201cCustomer Service\u201d ratio of \u00a35 or lower",
      "E-tech = \u00a328.55 / 5 = \u00a35.71 Four = \u00a322.95 / 5 = \u00a34.59 Airtec = \u00a321.00 / 4 = \u00a35.25 Star Services = \u00a340.00 / 8 = \u00a35 Electro = \u00a327.50 / 4 = \u00a36.88",
    ],
    picture: "16.png",
  },
  {
    question:
      'You want to sign up with the mobile provider with the highest average rating across "Eco-Friendliness" and "Network coverage", but you are willing to give up 1 point of this average rating for two points of "Customer Service". Which mobile provider do you sign up with?',
    options: [
      "E-tech",
      "Four",
      "Airtec",
      "Star Services",
      "Electro",
      "None of the above",
    ],
    answer: "B",
    hints: [
      "Step 1 \u2013 Calculate the average rating across \u201cEco-Friendliness\u201d and \u201cNetwork coverage\u201d for each provider",
      "E-tech = (10 + 3) / 2 = 6.5 Four = (5 + 5) / 2 = 5 Airtec = (8 + 6) / 2 = 7 Star Services = (7 + 8) / 7.5 = 6 Electro = (6 + 7) / 2 = 6.5",
      "Step 2 \u2013 Halve the \u201cCustomer Service\u201d rating for each provider, and add that to their average rating for \u201cEco-Friendliness\u201d and \u201cNetwork coverage\u201d, then select the highest.",
      "E-tech = 6.5 + (5 / 2) = 9 Four = 5 + (5 / 2) = 7.5 Airtec = 7 + (4 / 2) = 9 Star Services = 7.5 + (8 / 2) = 11.5 Electro = 6.5 + (4 / 2) = 8.5",
    ],
    picture: "16.png",
  },
  {
    question:
      "How much did the combined revenue from one-off games and food and drink differ from that of membership games between 2011-2014 inclusive (in \u00a3100,000s)?",
    options: ["1.57", "1.04", "1.74", "1.34"],
    answer: "A",
    hints: [
      "Step 1 \u2013 Calculate the totals for one-off games, food and drink and membership: One-off games = 0.6	+ 0.6	+ 0.5	+ 0.4	+ 0.5 = 2.6, Food and Drink = 1.1	+ 0.1 +	0.9	+ 0.8 +	0.8 = 3.7, Membership = 4.6	+ 4.4	+ 4.3 +	4.6	+ 4.1 = 22",
      "Step 2 \u2013 Calculate the difference 22 \u2013 3.7 \u2013 2.6 = 15.7",
      "Step 3 convert to 100,000s"
    ],
    picture: "4.png",
  },
  {
    question:
      "What was the average amount spent on food and drink in 2012 by each individual who attended Billiards Hero?",
    options: ["\u00a31.01", "\u00a30.99", "\u00a30.94", "\u00a30.87"],
    answer: "B",
    hints: [
      "Step 1 - Food and drink income/no of people = 8,000 / 8,500 = \u00a30.94",
    ],
    picture: "4.png",
  },
  {
    question:
      "There is a \u00a35 entrance fee for each person to enter Billiards Hero. In which year, or years, was the entrance fee revenue less than \u00a342,000?",
    options: ["2011, 2012", "2013, 2014", "2011, 2013,", "2011"],
    answer: "A",
    hints: [
      "Step 1 - Calculate the entrance fee revenue for each year, as follows:",
      "Attendee entrance fee revenue 2011 8,000 x 5 = \u00a340,000 2012 8,500 x 5 = \u00a342,500 2013 8,200 x 5 = \u00a341,000 and these repeat",
    ],
    picture: "4.png",
  },
  {
    question:
      "What will be the average annual change in attendance at Billiards Hero from 2011-2015 if the 2015 prediction proves to be accurate?",
    options: ["75", "150 increase", "100 increase", "125 increase"],
    answer: "D",
    hints: [
      "Step 1 \u2013 Calculate the total change in attendance 8,500 - 8,000 = 500",
      "Step 2 - Calculate the average yearly change in attendance 500 / 4 = 125 (dividing by 4, not 5 - think about why)",
    ],
    picture: "4.png",
  },
  {
    question:
      "Billiards Hero is subject to a takeover bid of 6 times its 2015 predicted revenues. The Board responds that it can deliver 10% added value through cost-cuttings to this purchase price. What valuation is the Board putting on Billiards Heros (in \u00a3 100,000s)?",
    options: ["43.12", "42.36", "41.58", "44.21"],
    answer: "C",
    hints: [
      "Step 1 - 2015 projected casino revenues = 0.6 + 1.1 + 4.6 = 6.3. 6.3 x 6 = 37.8, and 37.8 x 110% = 41.58 (\u00a310,000s)",
    ],
    picture: "4.png",
  },
  {
    question:
      "If the 2017 prediction is accurate, what is the average annual percentage change in Uptown University\u2019s wage spend across the timeframe shown?",
    options: ["0.85", "0.51", "0.61", "0.8"],
    answer: "D",
    hints: [
      "Step 1 - Calculate the average (3 + 1.2 - 0.8 - 0.2 + 0.6) / 5 = 0.8",
    ],
    picture: "5.png",
  },
  {
    question: "What is the wage spend projected to be for 2017?",
    options: ["\u00a30.37 million", "\u00a30.23 million", "\u00a30.21 million", "\u00a30.67 million"],
    answer: "A",
    hints: [
      "Step 1 \u2013 Calculate the total wage spend per line for 2016 (given in the table): Addition of 5 projects = 370.8 (\u00a31000\u2019s)",
      "Step 2 \u2013 From the graph we see that the 2017 predicted change in R&D spend is +0.6%, so add the 0.6%: 370,8000 x 100.6% = \u00a30.37 million",
    ],
    picture: "5.png",
  },
  {
    question: "What was the wage spend for 2015 (to the nearest \u00a31,000)?",
    options: ["\u00a3371,543", "\u00a3371,000", "\u00a3372,000", "\u00a3370,000"],
    answer: "C",
    hints: [
      "Step 1 \u2013 Total wage spend for 2016 is obtained from the table. Addition of 5 costs = 370,800 (as in previous question).",
      "Note 1: Notice that the graph gives change in wage spend compared with previous year. So in 2016 the change compared to 2015 was -0.2% from the graph. It is NOT the difference between -0.8% and -0.2% (i.e. +0.6%).",
      "Note 2: To get the correct percentage calculation think about a 0.2% drop from the 2015 figure to the 2016 figure. We would say [2015 figure] x (1 - 0.2%) = [2016 figure]. We have calculated the 2016 figure to be \u00a3370,800 so by rearranging we can find 2015.",
      "Step 2 \u2013 Calculate \u00a33370,800 \u00f7 0.998 = \u00a3371,543.1",
      "Step 3 round to the nearest 1,000: 372,000"
    ],
    picture: "5.png",
  },
  {
    question:
      "Hospitality overheads were 9% of wage spend in 2016. If hospitality overheads are projected to rise by 3% between 2016 and 2017, what percentage of 2017 predicted wage cost is the the 2017 predicted hospitality overhead cost?",
    options: ["9.00%", "9.03%", "9.06%", "9.08%"],
    answer: "D",
    hints: [
      "Step 1 - Total wage spend in 2016 was \u00a3370,800.",
      "Step 2 - Hospitality overheads were 9% of wage spend so 9% x \u00a3370,800 = \u00a333,372",
      "Step 3 - The graph tells us that the wage spend in 2017 is projected to increase by 0.8%. This will be \u00a3370,800 x 1.08 = \u00a3400,464",
      "Step 4 - Hospitality overheads are expected to increase by 3%. This will be \u00a333,372 x 1.09 = \u00a336,375.48.",
      "Step 4 - So the new percentage is 36,375.48 / 400,464 = 9.08%.",
    ],
    picture: "5.png",
  },
  {
    question:
      "If at the end of 2016 there was a 2.5% increase in the cost of each of the two most expensive lines, what is the total wage spend for 2016 (to the nearest \u00a31,000)?",
    options: ["\u00a3310,000", "\u00a3311,000", "\u00a3312,000", "\u00a3313,000"],
    answer: "B",
    hints: [
      "Step 1 \u2013 Calculate the new wage charge for the two most expensive 2016 lines: new research staff cost = 202.9 x 1.025 = 207.97, new pension pot spend = 101.3.8 x 0.025 = 103.83",
      "Step 2 \u2013 Add the new costs together. Total new spend =  = \u00a3311,805",
      "Note 1: alternatively, add the two lines together first and multiply by 1.025"
    ],
    picture: "5.png",
  },
  {
    question:
      "What is the number of shares not held by founders of StartUp Co as of 1 Jan 2020? Round your answer to the nearest integer if necessary.",
    options: ["2065", "1377", "2673", "1432"],
    answer: "B",
    hints: [
      "The data you need is in both the pie-chart and the table.",
      "Step 1 - The pie-chart shows that founders hold 60% of shares, so 40% of shares are held by investors and employees.",
      "Step 2 - From the table, the total number of founder shares as of 1 Jan 2020 is 605 + 720 + 515 + 225 = 2065",
      "Step 3 - 60% of the total number of shares is 2065, so 40% is 2065 / 6 * 4 = 1377 to the nearest integer",
    ],
    picture: "6.png",
  },
  {
    question:
      "Which founder bought or sold the largest number of shares over the 2 years shown?",
    options: ["Jack Smith", "Samira Patel", "Lauren Khan", "Chris Samson"],
    answer: "B",
    hints: [
      "Step 1 - The largest number of shares can be found by calculating the differences in shareholdings between the periods 1 Jan 2019 and 1 Jan 2021.",
      "Jack Smith buys 650 - 400 = 250 shares, Lauren Khan sells 800 - 200 = 600, Chris Samson buys 600 - 550 = 50, Samira Patel buys 370 - 250 = 120. So Lauren"
    ],
    picture: "6.png",
  },
  {
    question:
      "If Jack Smith sells 24% of his shares on 1 Jan 2021 at \u00a3£40.22 per share, how much is this trade worth to the nearest pound?",
    options: ["\u00a36,403", "\u00a36,274", "\u00a36,561", "\u00a36,784"],
    answer: "B",
    hints: [
      "Step 1 - From the table, Jack Smith holds 650 shares on 1 Jan 2021, 24% of his shares is 24% x 650 = 156, \u00a340.22 x 156 = \u00a36,274",
    ],
    picture: "6.png",
  },
  {
    question: "Which of the following statements is definitely true?",
    options: [
      "Employees and investors owned 40% of StartUp Co shares on 1 Jan 2021",
      "Samira Patel held the least shares of anyone on 1 Jan 2021",
      "Founders held 2065 shares in total on 1 Jan 2019",
      "The largest founder share dealing (purchase or sale) was 520 shares",
    ],
    answer: "D",
    hints: [
      "Step 1 - Go through checking whether each answer option is true or false",
      "Note 1 - The number of shares owned by founders has changed from 2020 to 2021, so it may have for employees and investors too. We cannot definitely say this is true.",
      "Note 2 - Samira Patel held the least shares of any founder on 1 Jan 2021, but we cannot say this is true of everyone (including investors and employees) - we don't know",
      "Note 3 - Founders held 2000 shares in total on 1 Jan 2019, not 2065 shares. This is false",
      "Note 4 - The largest Director share dealing was 520 shares which Lauren Khan sold between 2020 and 2021. This is true.",
    ],
    picture: "6.png",
  },
  {
    question:
      "If StartUp Co shares are worth \u00a332.65 on 1 Jan 2020, then what is the share valuation of the entire company to the nearest pound?",
    options: ["\u00a3120,440", "\u00a3104,460", "\u00a3108,456", "\u00a3112,370"],
    answer: "D",
    hints: [
      "Step 1 - Total number of founder shares = 2,065. This represents 60% of the total shares, so 100% of shares = 2065 / 60 * 100 = 3441.67 (don't round yet)",
      "Step 2 - valuation = 3441.67 x \u00a332.65 = \u00a3112,370",
    ],
    picture: "6.png",
  },
  {
    question:
      "The total number of \u00a3400,000 houses sold by Glenn Estates in 2018 represented 50% of their annual sales target. If this target was split equally across 5 salerooms, what was the individual sales target for each salesroom?",
    options: ["200", "194", "206", "214"],
    answer: "C",
    hints: [
      "Step 1 - Total \u00a3400,000 house sales = 225 + 290 = 515 houses",
      "Step 2 - 515 = annual target for 5 salesrooms * 50% so annual target = 515/0.5 = 1030",
      "Step 3 - 1030 target for 5 salesrooms so 1030 / 5 = target for 1 salesroom = 206",
    ],
    picture: "7.pNG",
  },
  {
    question:
      "Stamp duty of 3.5% is paid on house sales over \u00a3250,000. How much stamp duty was paid by house purchasers in 2018?",
    options: ["\u00a319,355,500", "\u00a320,356,000", "\u00a322,277,500", "\u00a324,912,000"],
    answer: "C",
    hints: [
      "Step 1 \u2013 Calculate the total number of houses where stamp duty is due",
      "\u00a3300,000 houses: 440 + 495 = 935 \u00a3400,000 houses: 225 + 290 = 515 \u00a3500,000 houses: 140 + 160 = 300",
      "Step 2 \u2013 Calculate the stamp duty due (drop the zeros in the prices for convenience)",
      "(935 x \u00a33 + 515 x \u00a34 + 300 x \u00a35) x 3.5% = \u00a3222.775 so total = \u00a322,277,500",
    ],
    picture: "7.pNG",
  },
  {
    question: "What is the total value of 2018 Glenn Estates house sales?",
    options: ["\u00a3766.5 million", "\u00a3787 million", "\u00a3760.5 million", "\u00a3770 million"],
    answer: "A",
    hints: [
      "Step 1 - Calculate the total number of houses sold for each price \u00a3200,000 - 300 + 350 = 650, \u00a3300,000 - 440 + 495 = 925, \u00a3400,000 - 225 + 290 = 515, \u00a3500,000 - 140 + 160 = 300",
      "Multiply each number of sales by sale price and sum (drop the zeros) = 650 x 2 + 935 x 3 + 515 x 4 + 300 x 5 = 7665 so total = \u00a3766,500,000",
    ],
    picture: "7.pNG",
  },
  {
    question:
      "In 2019, Glenn Estates house sales between Jan-June were the same as those in 2009, while the sales for the July-Dec period increase by a quarter. What is the difference in Glenn Estates house sales between July-Dec 2019 and Jan-June for 2019 (to the nearest \u00a3million)?",
    options: ["166", "155", "147", "173"],
    answer: "A",
    hints: [
      "Step 1 - Calculate the total sales for Jan to June:",
      "\u00a3200,000 x 300 + \u00a3300,000 x 440 + \u00a3400,000 x 225 +\u00a3500,000 x 140 = \u00a3352,000,000",
      "Step 2 - Calculate the total sales for July to Dec and apply the increase of a quarter = 0.25:",
      "\u00a3(200,000 x 350 + \u00a3300,000 x 495 x + \u00a3400,000 x 290 + \u00a3500,000 x 160) x 1.2 =\u00a3518,125,000",
      "Step 3 - Calculate the total difference between the two periods:",
      "\u00a3518,125,000 - \u00a3352,000,000 = \u00a3166,125,000",
    ],
    picture: "7.pNG",
  },
  {
    question:
      "A marketing drive is to be used to increase the value of Jan-June house sales to the value of July-December house sales. If each pound spent on marketing results in \u00a35 of increased sales, what value must be spent on marketing?",
    options: ["\u00a313.5 million", "\u00a314.5 million", "\u00a312.5 million", "\u00a311.5 million"],
    answer: "C",
    hints: [
      "Step 1 \u2013 Calculate the difference between the value of Jan-June house sales and the value of July-December house sales. From the previous question, this is millions is: 6 + 9 + 16 + 12.5 = \u00a362.5 million.",
      "Step 2 \u2013 Calculate the marketing spend needed \u00a362.5 / 5 = 12.5 (million).",
    ],
    picture: "7.pNG",
  },
  {
    question:
      "If the sales projections for next year prove accurate, which region will have maintained or increased sales levels each year from the previous year to next year?",
    options: ["EMEA", "LATAM", "North America", "APAC"],
    answer: "B",
    hints: [
      "Step 1 \u2013 Calculate the regional sales for the current year using the table.",
      "Step 2 \u2013 Calculate the ratios from Step 1 to the figures for the previous year and for next year, as follows;",
      "Eg for EMEA, 440 / 410 = 1.073 (7.3% increase) and 450 / 440 = 1.023 (2.3% increase)",
      "Repeating this process, we see the correct answer is LATAM",
    ],
    picture: "8.pNG",
  },
  {
    question:
      "What is the absolute difference between the lowest and the highest performing region (to the nearest \u00a3million) in the current year?",
    options: ["\u00a3428 million", "\u00a3422 million", "\u00a3435 million", "\u00a3398 million"],
    answer: "B",
    hints: [
      "Step 1 - Calculate the difference between the highest regional sales (EMEA) and the lowest regional sales (Other) 440 \u2013 18 = 422",
      "Thus, the correct answer is \u00a3422 million",
    ],
    picture: "8.png",
  },
  {
    question:
      "If next year\u2019s forecasts are scaled back by a third for EMEA and LATAM, and by a sixth for North America and APAC, what will be the total projected sales across all 5 regions?",
    options: ["\u00a3590.23 million", "\u00a3563.84 million", "\u00a3646.33 million", "\u00a3700.44 million"],
    answer: "C",
    hints: [
      "Step 1 - Calculate the new regional sales for the 4 regions affected and sum these",
      "(450 + 102) x 2/3 + (264 + 52) x 5/6 + 15 = 646.33",
      "Thus, the correct answer is \u00a3646.33 million",
    ],
    picture: "8.pNG",
  },
  {
    question:
      "What were the ratios for North America:Other sales for the previous year compared to the next year?",
    options: [
      "6:115 (Previous year); 5:88 (Next year)",
      "115:6 (Previous year); 88:5 (Next year)",
      "230:12 (Previous year); 15:264 (Next year)",
      "230:12 (Previous year); 251:18 (Next year)"],
    answer: "A",
    hints: [
      "Step 1 \u2013 Put the previous year\u2019s Other sales into a ratio 12:230",
      "Step 2 \u2013 Put the current Year\u2019s North America sales into a ratio 15:264",
      "Step 3 \u2013 Simplify these ratios by dividing by the highest common factors (2 and 3 respectively) to get 6:115 and 5:88"
    ],
    picture: "8.pNG",
  },
  {
    question:
      "Put the regions in decreasing order of total combined sales for the current year and next year\u2019s projection?",
    options: [
      "Other, APAC, LATAM, North America, EMEA",
      "North America, EMEA, LATAM, APAC, Other",
      "Other, North America, APAC, LATAM, EMEA",
      "EMEA, North America, LATAM, APAC, Other"],
    answer: "D",
    hints: [
      "Step 1 - Calculate the totals for each region: EMEA - 410 + 440 = 450, LATAM - 98 + 102 = 200, North America - 251 + 264 = 515, APAC - 46 + 52 = 98, Other - 18 + 15 = 33",
      "Step 2 - Order these in decreasing order",
    ],
    picture: "8.pNG",
  },
  {
    question:
      "In which year (or years) was there more than a 2.2% difference in the GDP per capita for Etopia compared to Newland?",
    options: ["2016, 2017", "2016, 2017, 2019", "2017, 2019", "2015, 2018"],
    answer: "A",
    hints: [
      "Step 1 \u2013 Calculate the % differences for each year by taking ratios",
      "Eg for 2015, 16,570 / 16,500 = 1.00424 (0.4% change). Continuing, we see the correct answer is 2016 and 2017.",
    ],
    picture: "9.pNG",
  },
  {
    question: "Which of the following statements is false?",
    options: [
      "The 2015-2019 range of Etopia GDP per capita is \u00241,295",
      "The average GDP of the countries shown in 2019 is \u00242.6 trillion",
      "The average of Newland's GDP per capita is \u002417,040",
      "The average GDP per capita for the 5 countries shown is 45,419"
    ],
    answer: "B",
    hints: [
      "Step 1 - Go through each of the answer options checking if it is true or false:",
      "a) is true since range = maximum - minimum = 17,865 - 16570 = 1,295",
      "b) is false since the average GDP is \u00242.591 trillion - the question did not mention rounding.",
      "c) is true since the average is (16,500 + 16,650 + 16,885 + 17,275 + 17890) / 5 = 17.040",
      "d) is true: the average GDP per capita can be calculated similarly to option c)",
    ],
    picture: "9.pNG",
  },
  {
    question:
      "Which two countries had the smallest difference in GDP per capita in 2009?",
    options: ["UK, Italy", "Italy, France", "France, UK", "Germany, UK"],
    answer: "C",
    hints: [
      "From looking at the table there smallest difference is between UK and France (49,799 - 48,727 = 1,072)",
    ],
    picture: "9.pNG",
  },
  {
    question:
      "Of the years shown, which year had the biggest total percentage GDP increase?",
    options: ["2016", "2017", "2018", "2019"],
    answer: "B",
    hints: [
      "Step 1 - Calculate the total GDP per capita for each year: 2015 - 33,070, 2016 - 33,750, 2017 - 34,750, 2018 - 34,605, 2019 - 35,390",
      "Step 2 - Calculate successive ratios to see the biggest total percentage increase: 33,750 / 33,070 = 1.021 (2.1% increase), 34,750 / 33,750 = 1.030, 2018 is a decrease so ignore and 35,390 / 34,605 = 1.023"
    ],
    picture: "9.pNG",
  },
  {
    question:
      "What was the average GDP per capita for Etopia and Newland across the 5 years shown?",
    options: [
      "\u002417,723 (Etopia); \u002417,030 (Newland)",
      "\u002417,723 (Etopia); \u002417,040 (Newland)",
      "\u002417,273 (Etopia); \u002417,030 (Newland)",
      "\u002417,273 (Etopia); \u002417,040 (Newland)"],
    answer: "D",
    hints: [
      "Step 1 - Calculate the averages: Etopia (16,570 + 17,100 + 17.865 + 17,330 + 17,500) / 5 = 17,372",
      "Newland (16,500 + 16,650 + 16,885 + 17,375 + 17,890) / 5 = 17,040"
    ],
    picture: "9.pNG",
  },
  {
    question:
      "What was the percentage change in revenue for North America between November and December 2019 to the nearest percent?",
    options: ["2%", "1%", "0%", "-1%"],
    answer: "D",
    hints: [
      "Step 1:Calculate the November revenue: this is in the pie chart - dropping the millions, 30 x 30% = 9",
      "Step 2:Calculate the December revenue: this is in the graph - 9",
      "Step 3:Since these are the same, the percentage change is 0",
    ],
    picture: "10.pNG",
  },
  {
    question:
      "What was the ratio of EMEA revenue: LATAM revenue in December 2019?",
    options: ["21:27", "55:66", "5.5:7.2", "33:37"],
    answer: "D",
    hints: [
      "Step 1 - The information is in the graph. EMEA in December 2019 is 6.6 and LATAM is 7.4",
      "Step 2 - Multiply by 10 and divide by common factor of 2 (or just multiply by 5 directly) to get 66:74 and then 33:37",
    ],
    picture: "10.png",
  },
  {
    question:
      "In January 2020 which two regions combined had the total revenue of the North America region?",
    options: [
      "EMEA and APAC",
      "LATAM and Other",
      "LATAM and APAC",
      "APAC and Other",
    ],
    answer: "B",
    hints: [
      "Step 1 - the information required is in the graph. Since these calculations are relatively simple, you can check individually",
      "Step 2 - EMEA + APAC = 5.5 + 4.4 = 9.9, LATAM + Other = 7.2 + 2.5 = 9.7, LATAM + APAC = 7.2 + 4.4 = 11.6, APAC + Other = 4.4 + 2.5 = 6.9, so option B",
      "Note that by looking at the table one can quickly see which combinations are likely to be close (options A and B)",
    ],
    picture: "10.pNG",
  },
  {
    question: "Which of the following is least in value?",
    options: [
      "Overall change in revenue for APAC from November 2019 to January 2020",
      "Sum of individual changes in revenue for Other from November 2019 to January 2020",
      "Percentage growth for North America revenue from December 2019 to January 2020",
      "The average change in value from December 2019 to January 2020",
    ],
    answer: "B",
    hints: [
      "Step 1 - option C is a percentage whereas the others are absolutes in millions, so we immediately discount C",
      "Step 2 - option A: APAC November 2019 value is 15% x 30 = 4.5, January value is 4.4 so difference of 0.1",
      "Step 3 - option B: Other November 2019 value is 7% x 30 = 2.1, December value is 2.2, January is 2.5 so overall is 0.4",
      "Step 4 - option D: average change is (-1.1 - 0.2 + 0.7 + 0.1 + 0.3) / 5 = -0.04, so this is the answer",
      "Note - it is possible to save some time by observing option D is negative whilst the others are positive, but this is tricky"
    ],
    picture: "10.pNG",
  },
  {
    question:
      "Suppose from January to February 2020 the revenue grew by 7% for EMEA, Latam and North America and decreased by 2.5% for APAC and Other. What is the total revenue in February 2020 to the nearest million?",
    options: ["\u00a231", "\u00a230", "\u00a232", "\u00a229"],
    answer: "A",
    hints: [
      "Step 1 - the information required is in the graph. Add the values for EMEA, Latam and North America to get 5.5 + 7.2 + 9.7 = 22.4.",
      "Step 2 - add the values for APAC and Other to get 4.4 + 2.5 = 6.9",
      "Step 3 - calculate the new amounts and add them: 7% extra is 1.07 and 2.5% less is 0.975: 1.07 x 22.4 + 0.975 x 6.9 = 30.7 to 1dp, so A",
    ],
    picture: "10.pNG",
  },
  {
    question:
      "Across all store locations and months, what was the overall difference of actual versus forecasted sales?",
    options: [
      "69",
      "75",
      "162",
      "18",
    ],
    answer: "A",
    hints: [
      "Step 1: Work out total forecasted sales and total actual sales for each month: Month 1 forecast = 868, actual = 854, month 2 forecast = 801, actual = 855, month 3 forecast = 840, actual = 869",
      "Step 2: Add all the total forecasted sales and subtract the total actual sales: 868 + 801 + 840 - 854 - 855 - 869 = 69",
      "Note: alternatively, find the difference between actual and forecast first for each month and location and sum these - this is quicker if you can do the initial subtraction in your head, but easier to make a mistake"
    ],
    picture: "11.png",
  },
  {
    question:
      "Over the three month period, which store achieved the highest sales per sales staff member?",
    options: ["London", "Manchester", "Birmingham", "St Albans"],
    answer: "D",
    hints: [
      "Step 1: Calculate each store's total actual sales, eg London = 300 + 266 + 249 = 815",
      "Similarly Manchester = 636, Birmingham = 496, St Albans = 297, Newcastle can be left out as it is not an option",  
      "Step 2: divide by number of sales staff members, eg London 815 / 12 = 68. Similarly Manchester = 71, Birmingham = 71, St Albans = 99",
      "Note: by looking at the data, one should hypothesise the answer is likely St Albans"
    ],
    picture: "11.png",
  },
  {
    question:
      "In Month 4, London outperformed its Month 3 target by 16%, whilst St Albans underperformed its Month 3 target by 40%. What is the difference in units sold per staff member to 1 decimal place?",
    options: [
      "6",
      "6.1",
      "6.2",
      "6.17",
    ],
    answer: "D",
    hints: [
      "Step 1: Calculate the Month 4 sales for each region: for London this is 250 x (1 + 16%) = 250 x 1.16 = 290 and for St Albans this is 54 x (1 - 40%) = 54 x 0.6 = 18",
      "Step 2: Calculate the ratio of units sold per staff member: for London this is 290 / 12 = 24.166 and for St Albans this is 54 / 3 = 18",
      "Step 3: Calculate the difference 24.166 - 17 = 6.2 (1dp)",
      "Note: this questions asks for the answer to 1 decimal place"
    ],
    picture: "11.png",
  },
  {
    question:
      "Which location has the lowest ratio of total forecasted sales to staff members over the 3 months?",
    options: [
      "London",
      "Manchester",
      "Birmingham",
      "Newcastle",
    ],
    answer: "D",
    hints: [
      "Step 1: Calculate each store's total forecasted sales, eg London = 330 + 270 + 250 = 850",
      "Similarly Manchester = 615, Birmingham = 476, Newcastle = 353",  
      "Step 2 - Calculate the ratio of forecast sales per staff: for London this is 850 / 12 = 71 (1dp). Similarly Manchester = 68, Birmingham = 68, Newcastle = 59",
    ],
    picture: "11.pNG",
  },
  {
    question:
      "Next year staff numbers are to be reduced by 1 at stores with 6 or less staff, and by 2 staff at all other stores. Assuming sales are 20% lower than forecasted Month 3 sales, which store has the lowest sales per staff ratio?",
    options: [
      "London",
      "Manchester",
      "Birmingham",
      "Newcastle",
    ],
    answer: "D",
    hints: [
      "Step 1: Calculate the new staff numbers - London 10, Manchester 7, Birmingham 5, Newcastle 5 - note we don't need St Albans since this is not an option",
      "Step 2: calculate new sales: this is 90% = 0.9 times each forecasted Month 3 sale. London - 225, Manchester - 189, Birmingham - 153, Newcastle - 108 ",
      "Step 3: calculate new ratios: London - 225 / 10 = 22.5, Manchester - 189 / 7 = 27, Birmingahm - 153 / 5 = 30.6, Newcastle = 108 / 5 = 21.6",
    ],
    picture: "11.pNG",
  },
  {
    question:
      "Which cuisine had the highest overall sales for Spring, Summer and Autumn combined?",
    options: [
      "Italian",
      "French",
      "Spanish",
      "Indian",
    ],
    answer: "D",
    hints: [
      "Step 1: the information required for this is in the graph",
      "Step 2: since Indian is the highest income cuisine for each season and we are finding the total, it has the highest total sales overall",
      "Note: it is not necessary to calculate the individual incomes - this question benefits those who think about the data before jumping into calculations"
    ],
    picture: "12.pNG",
  },
  {
    question:
      "The Winter income per cuisine is in the same ratio as Summer. What is the Winter income from French cuisine in thousands?",
    options: [
      "Cannot tell from the data provided",
      "\u00a340.7",
      "\u00a343.2",
      "\u00a341.5",
    ],
    answer: "B",
    hints: [
      "Step 1 - both the graph and the table are required for this question, as well as the assumption.",
      "Step 2 - Find the total Winter income by finding the total Winter income for Indian (50) then using the ratio.",
      "Step 3 - Find the ratio of Indian to French for Summer: this is 59 / 48 = 1.22917 ",
      "Step 4 - Divide the Winter Indian sales by this ratio to get total Winter French sales - 50 / 1.22917 = 40.68 (2dp)",
      "Step 5 - Find the ratio of "
    ],
    picture: "12.pNG",
  },
  {
    question:
      "What is the total sales by waiters across all seasons in ascending order?",
    options: [
      "Nikki, Jack, Jonah, Aidan",
      "Nikki, Jonah, Jack, Aidan",
      "Jack, Jonah, Aidan, Nikki",
      "Jack, Jonah, Nikki, Aidan",
    ],
    answer: "C",
    hints: [
      "The information that you need is shown in the table.",
      "Step 1: Calculate total sales for each waiter: For Nikki this is 15 + 26 + 33 + 24 = 98 (it is also clear she is the highest - no need to calculate this)",
      "Similarly, Jack has 41 sales, Aidan has 49 and Jonah has 42. ",
      "Step 2: put them in ascending (increasing) order: Jack, Jonah, Aidan, Nikki",
      "Note: it can also immediately be seen that the answer is C, since Nikki is the highest and we are placing in ascending order"
    ],
    picture: "12.pNG",
  },
  {
    question:
      "A trainee waiter joins for the next year working overtime and makes 15% of total Indian sales from all the waiters. How much in sales does the trainee make?",
    options: [
      "\u00a323,000",
      "\u00a333,000",
      "\u00a334,500",
      "\u00a343,000",
    ],
    answer: "C",
    hints: [
      "Step 1 - the quickest way to do this is to sum the total Indian sales - this can be done via the graph or table, but the graph is quicker, but the table is required for Winter",
      "Step 2 - Spring + Summer + Autumn sales = 50 + 59 + 71 = 180. Total Winter sales = 24 + 9 + 10 + 7 = 50. Summing the seasons thus gives 180 + 50 = 230",
      "Step 3 - multiply this by 15% to get 230 x 15% = 34.5. Thus the answer is \u00a334,500",
      "Note to use the table we require the assumption stated. An even faster way to answer this question is to use the numbers from the previous question",
    ],
    picture: "12.pNG",
  },
  {
    question:
    "What percentage of total sales from Spring to Autumn are French and Indian meals to 1 decimal place?",
    options: [
      "56.5%",
      "56.6%",
      "56.4%",
      "56.7%",
    ],
    answer: "B",
    hints: [
      "Step 1 - the information required is shown in the graph. Sum the total sales across regions. For Spring we have 30 + 34 + 43 + 50 = 157. Similarly Summer = 194 and Autumn = 183. The sum is 534",
      "Step 2 - calculate the total French and Spanish meals. For Spring this is 34 + 50 = 84, for Summer it is 48 + 59 = 107, for Autumn it is 25 + 47 = 72. The sum is 302",
      "Step 3 - calculate the ratio 302 / 534 = 0.5655 = 56.6% (1dp)",
    ],
    picture: "12.pNG",
  },
  {
    question:
      "On average, 65% of client meetings are at the client's office. The average distance to travel to client office is 63 miles across all countries. Assuming a travel cost of 49p per mile, what is the total travel cost for March across all countries?",
    options: [
      "\u00a37,624.89",
      "\u00a37,756.13",
      "\u00a38,013.34",
      "\u00a38,131.77",
    ],
    answer: "A",
    hints: [
      "Step 1: calculate total meetings in March: 93 + 133 + 154 = 380",
      "Step 2: calculate number of meetings which require travel: 65% x 380 = 247",
      "Step 3: calculate overall cost by multiplying number of trips x number of miles x cost per mile = 247 x 63 x \u00a30.49 = \u00a37624.89"
    ],
    picture: "13.png",
  },
  {
    question:
      "If the percentage change in meetings between April and May (projected) continues for June, how many meetings will the UK and Spain regions have respectively?",
    options: [
      "143 (UK); 138 (Spain)",
      "140 (UK); 145 (Spain)",
      "126 (UK); 148 (Spain)",
      "120 (UK); 150 (Spain)",
    ],
    answer: "C",
    hints: [
      "Step 1: calculate the percentage change in meetings between April and May (projected) for UK and Spain: for UK 143 / 154 = 0.92857 = 92.857%, for Spain 138 / 133 = 1.0376 = 103.76% ",
      "Step 2: calculate the projected number of meetings for June: for UK 92.857% x 136 = 126.3, for Spain 103.76% x 143 = 148.4. Round these since the number of meetings must be a whole number",
      "Note: this calculation can be carried out in one line, eg for UK 143 / 154 x 136 = 126.3"
    ],
    picture: "13.png",
  },
  {
    question:
      "If the margin of error on May\u2019s projected client visits is +/- 7%, what are the ranges for each region, rounded to the nearest whole number?",
    options: [
      "126\u2013146 (UK); 102\u2013118 (France); 133\u2013153 (Kim)",
      "126\u2013146 (UK); 102\u2013117 (France); 132\u2013151 (Kim)",
      "125\u2013145 (UK); 102\u2013118 (France); 133\u2013153 (Kim)",
      "125\u2013145 (UK); 102\u2013117 (France); 132\u2013151 (Kim)",
    ],
    answer: "A",
    hints: [
      "Step 1 - Calculate the 93% and 107% figures for each region (to the nearest whole visit). For the UK, this is 136 x 93% = 126.48 = 126 (rounded), 126 x 107% = 145.52 = 146 (rounded)",
      "This eliminates 2 of the possible 4 answers",
      "Step 2 - Calculate the 93% and 107% figures for France. This is 110 x 93% = 102.3 = 102 (rounded), 110 x 107% = 117.7 = 118 (rounded)",
      "This eliminates 2 of 4 answers, or 1 of 2 remaining answers. Thus the answer is A",
      "Note that one could have picked any 2 of 3 regions to work with for this problem",
    ],
    picture: "13.png",
  },
  {
    question:
      "Jessica, who travelled 3,500 miles in July, travelled an extra 10 miles per client visit compared to Simon. What was the total number of miles Simon travelled in July?",
    options: ["2,400", "2,600", "2,800", "3,000", "3,200"],
    answer: "B",
    hints: [
      "Step 1 - Let x = Jessica\u2019s average mileage per client visit July visits = 70 = 3,500 / x X = 3,500 / 70 = 50 miles per visit",
      "Step 2 - Calculate Simon\u2019s average mileage per client visit 50 \u2013 10 = 40 miles per visit",
      "Step 3 - Calculate the total number of miles Simon travelled in July 40 x 65 = 2,600 miles",
    ],
    picture: "13.pNG",
  },
  {
    question:
      "The average order value per client visit is \u00a3410, \u00a3501 and \u00a3565 for UK, France and Spain meetings respectively. Which regions generated the highest and lowest order values in March?",
    options: [
      "Spain (most); France (least)",
      "Spain (most); UK (least)",
      "UK (most); France (least)",
      "UK (most); Spain (least)",
    ],
    answer: "B",
    hints: [
      "Step 1 - Calculate each region's sales for March: UK - 154 x \u00a3410 = \u00a363,140, France - 93 * \u00a3501 = \u00a346,593, Spain - 133 * \u00a3565 = \u00a375,145",
      "Step 2 - notice the highest is Spain and the lowest is UK"
    ],
    picture: "13.png",
  },
  {
    question:
      "Which subsidiary will pay the lowest amount in dividends (interim and final dividends combined)?",
    options: [
      "Subsidiary 1",
      "Subsidiary 2",
      "Subsidiary 3",
      "Subsidiary 4",
    ],
    answer: "C",
    hints: [
      "Step 1 - we need to calculate (interim dividend + final dividend) x number of shares",
      "Step 2 - we can notice that Subsidiary 2 is much larger than the others, so we don't need to calculate it (5 is not an option so we don't calculate it either)",
      "Step 3 - calculate the formula from step 1 for Subsidiarys 1, 3 and 4: 96, 56.7 and 87.36 respectively. So the answer is 3"
    ],
    picture: "14.png",
  },
  {
    question:
      "Which 2 or 3 subsidiaries had combined sales of 29,807?",
    options: [
      "Subsidiaries 1 and 2",
      "Subsidiaries 2 and 4",
      "Subsidiaries 2, 3 and 4",
      "Subsidiaries 1, 2 and 3",
    ],
    answer: "D",
    hints: [
      "Step 1 - this question is best answered by elimination:",
      "Step 2 - for each option, calculate the sum. Option A: 28,856, Option B: 28,864, Option C: 29,815, Option D: 29,807",
    ],
    picture: "14.png",
  },
  {
    question:
      "Assume total sales across all subsidiaries increases by 7% year on year and number of staff decrease by 50 every year. What is the ratio of total sales to number of staff for end of year 5?",
    options: ["65, 57, 73, 69"],
    answer: "A",
    hints: [
      "Step 1 - calculate current total sales across all subsidiaries: 5,405 + 23,451 + 951 + 5,413 + 10,494 = 45,714",
      "Step 2 - calculate current total number of staff: 130 + 456 + 33 + 154 + 244 =  1,017",
      "Step 3 - calculate number of years to consider: we are end of year 2 (since we are seeing year 2 results), one change is to end of year 3, two to end of year 4, three to end of year 5",
      "Step 4 - calculate end of year 5 sales: this is compounded - 45,715 x 1.07^3 = 56,002.84",
      "Step 5 - calculate end of year 5 staff: this is not compounded (ie simple) - 1,017 - 3 x 50 = 867",
      "Step 6 - calculate the ratio 56,002.84 / 867 = 64.59 (2dp)",
    ],
    picture: "14.pNG",
  },
  {
    question:
      "What is the ratio of Subsidiary 4\u2019s interim dividend per share:final dividend per share in simplest form?",
    options: ["14.3:13", "11:10", "13:14.3", "143:13"],
    answer: "B",
    hints: [
      "Step 1 - compare the ratio 14.3 / 13 = 143 / 130 = 11 / 10 by dividing by common factor 13 (or 1.3 from the first equation)",
    ],
    picture: "14.pNG",
  },
  {
    question:
      "What is the lowest average cost per staff member across the 5 subsidiaries to the nearest thousand?",
    options: ["\u00a3126,000", "\u00a3127,000", "\u00a3118,000", "\u00a3114,000"],
    answer: "D",
    hints: [
      "Step 1 - Calculate the ratio for each subsidiary (dropping £100,000s): 1 - 153 / 130 = 1.18, 2 - 786 / 456 = 1.72, 3 - 41 / 33 = 1.24, 4 - 175 / 154 = 1.14, 5 - 307 / 244 = 1.26",
    ],
    picture: "14.pNG",
  },
  {
    question:
      "If profit before tax increases by 7% for Company B and decreases by 4% for Company A, what is the difference between Company A and Company B\u2019s corporation tax payments (to the nearest \u00a3million)?",
    options: [
      "\u00a35 million",
      "\u00a36 million",
      "\u00a37 million",
      "\u00a38 million",
    ],
    answer: "C",
    hints: [
      "Note: Don\u2019t be caught out by the fact that the question lists Company B first, when you might be expecting to see Company A then Company B. This can throw those not paying attention.",
      "Step 1 - add 7% to company B\u2019s profit before tax: 115 x 1.07 = 123.05",
      "Step 2 - decrease company A\u2019s profit before tax by 4%: 150 x 0.96 = 144",
      "Step 3 - calculate the difference in corporation tax (at 33%) (144 \u2013 123.05) x 33% = 6.9135 = \u00a37 million (to the nearest \u00a3million)",
    ],
    picture: "15.pNG",
  },
  {
    question:
      "Company B and Company C choose to declare their revenues in US dollars and Euros respectively. Using only the data provided, what are these values?",
    options: [
      "$1,673 million (company B); \u20ac1005.4 million (company C)",
      "$1,652 million (company B); \u20ac1003.7 million (company C)",
      "$1,683 million (company B); \u20ac1009.3 million (company C)",
      "Cannot tell",
    ],
    answer: "D",
    hints: [
      "Step 1: we are not given GBPUSD and GBPEUR rates. Since the question said using only the data provided, we cannot answer the question",
    ],
    picture: "15.png",
  },
  {
    question:
      "If profit before tax were 7% higher, what would be the profit after tax for Company B to 2dp?",
    options: [
      "\u00a385.05",
      "\u00a382.44",
      "\u00a381.51",
      "\u00a383.18",
    ],
    answer: "B",
    hints: [
      "Step 1 - calculate the new profit before tax: 1.07 x 115 = 123.05",
      "Step 2 - calculate the new corporation tax: 40.6065",
      "Step 3 - calculate the profit after tax by subtracting corporation tax from profit before tax: 123.05 - 40.6065 = 82.4435 = 82.44 (2dp)"
    ],
    picture: "15.pNG",
  },
  {
    question:
      "What was the average gross profit across the 3 companies to the nearest \u00a310million?",
    options: [
      "\u00a3167 million",
      "\u00a3168 million",
      "\u00a3169 million",
      "\u00a3170 million",
    ],
    answer: "D",
    hints: [
      "Step 1 \u2013 Calculate the total gross profit 201 + 144 + 158 = 503",
      "Step 2 \u2013 Calculate the average 503 / 3 = 167.66",
      "Step 3 \u2013 To the nearest \u00a310million this is \u00a3170 million",
    ],
    picture: "15.png",
  },
  {
    question:
      "What is the order of companies in decreasing order of profit after tax:revenue ratios?",
    options: [
      "A, C, B",
      "A, B, C",
      "C, B, A",
      "C, A, B",
    ],
    answer: "A",
    hints: [
      "Step 1 - calculate the profit after tax:revenue ratio for each company: Company A - 100 / 1,056 = 0.947, Company B - 77 / 1,195 = 0.0642, Company C - 85 / 914 = 0.0934",
      "Step 2 - order these in decreasing order: A, C, B",
    ],
    picture: "15.png",
  },
];
