import React from "react";
import {useHistory} from "react-router-dom";
import * as Links from "../../../layouts/Links";

export default function CallToAction() {

  let history = useHistory();

  return (
    <div
      className="bg-white dark:bg-gray-800 rounded-xl shadow-3xl mx-auto px-12">
      <div
        className="lg:flex lg:items-center lg:justify-between w-full py-12 px-12 md:px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2
          className="text-3xl font-bold text-black dark:text-white sm:text-4xl">
            <span className="block">
                What are you waiting for?
            </span>
          <span className="block text-casemath-blue">
                Sign up now.
            </span>
        </h2>
        <div className="lg:mt-0 lg:flex-shrink-0">
          <div className=" inline-flex rounded-md shadow">
            <button type="button"
                    className="py-4 px-6  bg-casemath-blue hover:bg-blue-800
                    focus:ring-indigo-500 focus:ring-offset-casemath-blue
                    text-white w-full transition ease-in duration-200
                    text-center text-base font-semibold shadow-md
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    rounded-lg uppercase"
                    onClick={() => history.push(Links.AuthRegister)}>
              Get Started Free
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
