import React from "react";
import {Mixpanel} from "../../../../../utils/marketing/Mixpanel";
import Hints from "../../QuestionProcessors/Hints";

function MCQOption(letter, choices, setChoices, practiceqs, questiono) {
  let border = (choices[questiono] === letter) ? "border-casemath-blue" :
    "border-gray-200"

  function handleAnswerSelection() {
    setChoices(() => ({
      ...choices,
      [questiono]: letter,
    }))
  }

  return <>
    {
      <button
        className={`shadow-sm focus:outline-none hover:shadow-lg ease-in-out 
        transition-all duration-150 rounded-lg bg-white border-4 px-3 flex 
        py-4 my-2 w-full ${border}`
        }
        onClick={handleAnswerSelection}>
        <span className="flex text-left flex-grow capitalize">
            <span className="capitalize font-bold">{letter}</span>
        </span>
        <span className="text-right flex-grow">
          {practiceqs[questiono].options[letter]}
        </span>
      </button>
    }
  </>;
}

export function questionsQuestionUI(
  Header,
  questiono,
  practiceqs,
  choices,
  setChoices,
  handleQChange,
  hintAllowed,
  setHide,
  hide,
  setQuestionNo,
  handleView) {

  function EndPage() {
    return <div className="mx-auto">
      <div
        className="font-semibold text-center flex justify-center
        text-casemath-blue mx-auto">
        You've gone through all the questions. We recommend you go
        back and check through your answers while you have time!
      </div>
      <br/>
      <div
        className="text-casemath-blue font-semibold text-center justify-center
        mx-auto">
        You can select the question to revisit on the top left. Or if
        you're sure, you can proceed to see the answers.
      </div>
      <br/>
      <br/>

      <button
        className="text-white font-bold px-6 py-4 rounded outline-none
        focus:outline-none mr-1 mb-1 bg-casemath-blue active:bg-blue-600
         uppercase text-sm shadow hover:shadow-lg ease-linear transition-all
         duration-150 w-full"
        onClick={() => setQuestionNo(0)}
      >
        Go back to first question
      </button>

      <button
        className="text-white font-bold px-6 py-4 rounded outline-none
        focus:outline-none mr-1 mb-1 bg-pink-400 active:bg-pink-200
         uppercase text-sm shadow hover:shadow-lg ease-linear transition-all
         duration-150 w-full"
        onClick={() => handleView("answers")}
      >
        Proceed to Answers
      </button>

      <br/>
    </div>;
  }

  Mixpanel.track("Begin Test", {"start_time": new Date()})

  return (
    <div className="h-screen w-fullscreen">
      <Header/>
      <div className="flex flex-row">
        <div className="w-full bg-white md:w-2/3 lg:w-2/3 xl:w-2/3">
          <div className="m-8 h-full">
            {questiono <= practiceqs.length - 1 && (
              <img
                className={"mx-auto my-auto"}
                src={require(`questionEngine/parse_papers/question_imgs/${practiceqs[questiono].picture.toUpperCase()}`)}
                alt={practiceqs[questiono].picture.toUpperCase()}/>
            )}
          </div>
        </div>

        <div
          className="bg-gray-200 w-full md:w-1/4 lg:w-1/3 xl:w-1/3 p-8 shadow-xl
           overflow-hidden">
          <div
            className="container px-12 mx-auto flex flex-wrap flex-col
            items-center justify-between">
            <div
              className="w-full relative flex flex-col hover:justify-between
              lg:w-auto lg:static lg:block lg:justify-start space-between
              align-middle items-center">
              {questiono <= practiceqs.length - 1 ? (
                <div>
                  <br/>
                  {practiceqs[questiono].question}
                  <div className="">
                    <div className="grid gap-4 py-4">
                      {MCQOption("a", choices, setChoices, practiceqs,
                        questiono)}
                      {MCQOption("b", choices, setChoices, practiceqs,
                        questiono)}
                      {MCQOption("c", choices, setChoices, practiceqs,
                        questiono)}
                      {MCQOption("d", choices, setChoices, practiceqs,
                        questiono)}
                    </div>
                  </div>

                  <div className="flex flex-end">
                    <button
                      onClick={() => {
                        handleQChange();
                        // generateNums();
                      }}
                      className="text-white font-bold px-6 py-4 rounded
                      outline-none focus:outline-none mr-1 mb-1 bg-casemath-blue
                      active:bg-blue-600 uppercase text-sm shadow
                      hover:shadow-lg ease-linear transition-all duration-150"
                    >
                      Next
                    </button>
                  </div>
                  {hintAllowed && (
                    <div>
                      <div className="text-sm font-semibold">
                        <button
                          className="text-white font-bold px-6 py-4 rounded
                      outline-none focus:outline-none mr-1 mb-1 bg-red-600
                      active:bg-blue-600 uppercase text-sm shadow
                      hover:shadow-lg ease-linear transition-all duration-150"
                          onClick={() => setHide(false)}>
                          I need a hint
                        </button>
                      </div>

                      {hide ? null : <Hints sethide={setHide}/>}
                    </div>
                  )}
                </div>
              ) : EndPage()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
