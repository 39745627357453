import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import Canvas from "utils/effects/ParticlesCanvas";
import {Mixpanel} from "../../utils/marketing/Mixpanel";

const Links = require("layouts/Links");

export default function ContactUs() {

  Mixpanel.track("Entered Contact Us Page")

  return (
    <div>
      <IndexNavbar fixed/>
      <div
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <Canvas className="absolute"/>


        <div className="container mx-auto px-4 h-full">
          <div
            className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div
                className="relative flex flex-col min-w-0 break-words w-full mb-6
            shadow-lg rounded-lg bg-gray-300 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <h2
                    className="font-sans text-center text-2xl font-bold tracking-tight text-casemath-blue sm:text-4xl sm:leading-none">
                    Contact/Report bug
                    <br/>
                    <br/>
                  </h2>
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs
                    font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Full Name
                      </label>
                      <input
                        id="name"
                        name="fullname"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700
                    bg-white rounded text-sm shadow focus:outline-none
                    focus:shadow-outline w-full ease-linear transition-all
                    duration-150"
                        placeholder="Name"
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold
                    mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700
                    bg-white rounded text-sm shadow focus:outline-none
                    focus:shadow-outline w-full ease-linear transition-all
                    duration-150"
                        placeholder="Email"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs
                    font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700
                    bg-white rounded text-sm shadow focus:outline-none
                    focus:shadow-outline w-full ease-linear transition-all
                    duration-150"
                        placeholder="Enter your message here"
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-casemath-blue text-white active:bg-gray-700 text-sm
                     font-bold uppercase px-6 py-3 rounded shadow
                     hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
                     w-full ease-linear transition-all duration-150"
                        type="button"
                        onclick="sendEmail()"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

{/*
 use 'strict'

 var aws = require('aws-sdk');

 // Provide the full path to your config.json file.
 aws.config.loadFromPath('config.json');

 // Replace sender@example.com with your "From" address.
 // This address must be verified with Amazon SES.
 const sender = "Casemath Support <hello@casemath.co.uk>";

 // Replace recipient@example.com with a "To" address. If your account
 // is still in the sandbox, this address must be verified.
 const recipient = document.getElementsByTagName("email");

 // Specify a configuration set. If you do not want to use a configuration
 // set, comment the following variable, and the
 // ConfigurationSetName : configuration_set argument below.
 const configuration_set = "ConfigSet";

 // The subject line for the email.
 const subject = "Test";


 // The character encoding for the email.
 const charset = "UTF-8";

 // Create a new SES object.
 var ses = new aws.SES();

 // Specify the parameters to pass to the API.
 var params = {
 Source: sender,
 Destination: {
 ToAddresses: [
 recipient
 ],
 },
 Message: {
 Subject: {
 Data: "Contact",
 Charset: charset
 },
 Body: {
 Text: {
 Data: document.getElementsByTagName("message"),
 Charset: charset
 },
 }
 },
 ConfigurationSetName: configuration_set
 };

 //Try to send the email.
 ses.sendEmail(params, function(err, data) {
 // If something goes wrong, print an error message.
 if(err) {
 console.log(err.message);
 } else {
 console.log("Email sent! Message ID: ", data.MessageId);
 }
 });

 */
}