import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Container, Grid, FormControl, FormLabel } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PayByStripe from "./PayByStripe";
import Radio from "@material-ui/core/Radio";
import Datepicker from "react-datepicker";
import RadioGroup from "@material-ui/core/RadioGroup";
import ReactSelect from "react-select";
import options from "./Timezones";
import { tiers } from "./Pricing";
import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  backButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textArea: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  datePicker: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

//TODO: APIify
const availableTimes = [9, 18];

function getSteps() {
  return ["Choose Timings", "Your Details", "Payment Gateway"];
}

const initialFormState = {
  tier: "",
  date: "",
  time: "",
  firstname: "",
  surname: "",
  email: "",
  paid: false,
};

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [formstate, updateFormstate] = useState(initialFormState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [price, setPrice] = useState(null);
  const steps = getSteps();
  const { register, handleSubmit, control, errors, reset } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (activeStep === steps.length - 2) {
      //TODO: Take some action when form is complete - payment platform ?
      console.log(formstate);
    }
    console.log(data);
  };

  function onChange(e) {
    e.persist();
    updateFormstate(() => ({ ...formstate, [e.target.name]: e.target.value }));
    if (e.target.name === "tier") {
      getPrice();
    }
  }

  const handleNext = () => {
    console.log();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const formatTime = (n) => {
    if (n >= 12) return `${n}:00 PM`;
    return `${n}:00 AM`;
  };

  const getPrice = () => {
    tiers.forEach((t) => {
      if (t.title === formstate.tier) {
        setPrice(t.price);
      }
    });
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Card className="shadow-2xl">
            <CardContent>
              <Typography className={classes.title} color="textPrimary">
                Particular areas you would like to focus on
              </Typography>

              <Grid container spacing={3}>
                <Grid item sm={12}></Grid>
                <Grid item sm={6}>
                  <TextField
                    id="tier"
                    select
                    name="tier"
                    label="Session type"
                    isRequired="true"
                    onChange={onChange}
                    error={errors.tier}
                    inputRef={register}
                    SelectProps={{
                      native: true,
                    }}
                    helperText="Please select the course tier"
                    variant="outlined"
                  >
                    {tiers.map((tiern) => (
                      <option key={tiern.title} value={tiern.title}>
                        {tiern.title}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    name="date"
                    error={errors.date}
                    isRequired="true"
                    id="date"
                    label="Date"
                    type="date"
                    defaultValue={new Date().getDate()}
                    onChange={onChange}
                    inputRef={register}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormControl
                    component="fieldset"
                    error={formstate.time === null}
                    className={classes.formControl}
                  >
                    <FormLabel component="legend">
                      The closest available time and dates are:
                    </FormLabel>
                    <RadioGroup
                      aria-label="time"
                      name="time"
                      onChange={onChange}
                    >
                      {availableTimes.map((n) => (
                        <FormControlLabel
                          value={formatTime(n)}
                          control={<Radio />}
                          label={formatTime(n)}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      case 1:
        return (
          <Card>
            <CardContent>
              <Typography className={classes.title} color="textPrimary">
                Your information
              </Typography>

              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <TextField
                    id="firstname"
                    label="First name"
                    type="firstname"
                    name="firstname"
                    isRequired="true"
                    error={errors.firstname}
                    onChange={onChange}
                    autoComplete="firstname"
                    defaultValue="Name"
                    margin="normal"
                    variant="outlined"
                    error={!!errors.firstname}
                    inputRef={register({
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="surname"
                    label="Surname"
                    type="surname"
                    name="surname"
                    isRequired="true"
                    error={errors.surname}
                    autoComplete="surname"
                    defaultValue="Surname"
                    onChange={onChange}
                    margin="normal"
                    variant="outlined"
                    error={!!errors.surname}
                    inputRef={register({
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="email"
                    label="Email address"
                    type="email"
                    name="email"
                    isRequired="true"
                    error={errors.email}
                    autoComplete="email"
                    defaultValue="Email"
                    onChange={onChange}
                    margin="normal"
                    variant="outlined"
                    error={!!errors.email}
                    inputRef={register({
                      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      case 2:
        return <PayByStripe price={price} tier={formstate.tier} />;
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography>All steps completed</Typography>
                <Button onClick={() => handleReset()}>Reset</Button>
              </div>
            ) : (
              <div>
                {getStepContent(activeStep)}
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={() => handleBack()}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  {activeStep !== steps.length - 2 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleNext()}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => handleNext()}
                    >
                      Pay
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </Container>
  );
}
