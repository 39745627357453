import {unprocessedData} from "../questionEngine/parse_papers/Data";

export function convertJSONtoQuestionFormat(testNo) {
  var processedQuestions = [];
  testNo = parseInt(testNo)
  unprocessedData.forEach((element) => {
    if (between(parseInt(element.picture.split(".")[0]), testNo)) {
      processedQuestions.push({
        question: element.question,
        options: {
          a: element.options[0],
          b: element.options[1],
          c: element.options[2],
          d: element.options[3],
        },
        answer: element.answer.toLowerCase(),
        picture: element.picture,
        hints: element.hints,
      });
    }
  });
  return processedQuestions;
}

function between(x, testNo) {
  var min = 0;
  var max = 0;
  switch (testNo) {
    case 1:
      min = 0;
      max = 4;
      break;
    case 2:
      min = 5;
      max = 7;
      break;
    case 3:
      min = 8;
      max = 10;
      break;
    case 4:
      min = 11;
      max = 13;
      break;
    case 5:
      min = 14;
      max = 15;
      break;
    case 6:
      min = 16;
      max = 16;

  }
  return x >= min && x <= max;
}