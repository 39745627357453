import React, { useState, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import Gravatar from "react-gravatar";

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const signOut = () => {
    Auth.signOut();
    window.location.href = "/";
  };

  const [userName, setUserName] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);

  async function getUserinfo() {
    let userinfo = await Auth.currentAuthenticatedUser();
    const { attributes, username } = userinfo;
    setUserName(username);
  }

  useEffect(() => {
    getUserinfo();
  }, []);

  const routeChangeToUserPage = () => {
    setRedirectTo("/user");
  };

  const routeChangeToTopicsPage = () => {
    setRedirectTo("/practice");
  };

  if (redirectTo != null) {
    return <Redirect to={`${redirectTo}`} />;
  }

  return (
    <>
      <a
        className="text-gray-600 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
            <Gravatar email={userName} size={150} className="rounded-full" />
            {/* <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={require("assets/img/casemath-logo.png")}
            /> */}
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <a
          href=""
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={() => routeChangeToUserPage()}
        >
          Profile
        </a>
        {/* <a
          href=""
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => e.preventDefault()}
        >
          User Settings
        </a> */}
        <a
          href=""
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={() => routeChangeToTopicsPage()}
        >
          Topics
        </a>
        <div className="h-0 my-2 border border-solid border-gray-200" />
        <a
          href=""
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-red-500"
          }
          onClick={() => signOut()}
        >
          Sign Out
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
