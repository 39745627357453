import React from "react";

export default function ConvertSanitytoQuestionFormat(practiceqs) {
  const convertQuestionFormats = (practiceqs) => {
    let processedQuestions = [];

    practiceqs.forEach((element) => {
      processedQuestions.push({
        question: element.question,
        options: {
          a: element.optiona,
          b: element.optionb,
          c: element.optionc,
          d: element.optiond,
        },
        answer: findCorrectAnswer(element).toLowerCase(),
        explanation: element.explanation,
        rating: element.rating[0] || null,
      });
    });
    return processedQuestions
  }

  const findCorrectAnswer = (element) => {
    if (element.answera) {
      return 'a'
    }
    if (element.answerb) {
      return 'b'
    }
    if (element.answerc) {
      return 'c'
    }
    if (element.answerd) {
      return 'd'
    }
  }

  return convertQuestionFormats(practiceqs);
}