import mixpanel from 'mixpanel-browser';
import React, {useState} from "react";

const Links = require("../../../layouts/Links");

/* -------------------------------------------------------------------------- */
/*                    Small walkthrough of our learning app                   */
/* -------------------------------------------------------------------------- */

//TODO: tips 1,2,3
//TODO: explanation (teaching pt)
const practiceqs = [
  {
    question:
      "What was the 2010 percentage change in the value of the Pacific Rim holding between October and November (to the nearest percentage)?",
    options: {
      a: "41% less",
      b: "36% less",
      c: "34% less",
      d: "33% less",
    },
    answer: "d",
  },
  {
    question:
      "What was the ratio of Pacific Rim: Southern Pacific holdings in October 2010?",
    options: {
      a: "2:3",
      b: "4:5",
      c: "5:4",
      d: "4:7",
    },
    answer: "d",
  },
  {
    question:
      "In October 2010, which two Pacific Bond fund holdings when combined had the same value as Southern Pacific holdings?",
    options: {
      a: "Northern Pacific and Central Pacific",
      b: "Central Pacific and Pacific Rim",
      c: "Pacific Mixed and Northern Pacific",
      d: "Pacific Rim and Norther Pacific",
    },
    answer: "b",
  },
  {
    question:
      "In October 2010 what fraction of the total Pacific Bond did the Pacific and Pacific Mixed fund holdings represent?",
    options: {
      a: "1/5",
      b: "1/10",
      c: "1/4",
      d: "3/10",
    },
    answer: "d",
  },
];

export default function PracticeDemo(props) {
  const [questiono, setquestiono] = useState(0);
  const [optionSelected, setoptionSelected] = useState(null);
  // const setoptionSelected = (val) => {
  //   setquestiono(questiono + 1);
  //   setOptionSelected(val);
  // };

  const handleQChange = () => {
    setquestiono(questiono + 1);
    setoptionSelected(null);
  };
  mixpanel.track("Interact with Demo Qs");
  return (
    <div>
      <div className="container mx-auto py-10">
        <div className="text-center items-center">
          <h3
            className="text-5xl mb-2 mt-2 font-bold leading-normal
            text-casemath-blue">
            Try a question
          </h3>
        </div>
        <div className="flex flex-wrap items-center">
          <div
            className="w-full mx-4 md:w-6/12 ml-auto mr-auto mt-10
            bg-white shadow-2xl rounded">
            <div className="flex-col">
              <h3
                className="text-lg font-semibold leading-relaxed mt-4 mb-4
                text-casemath-blue text-center">
                Graph Analytics and Interpretation
              </h3>

              <img
                alt="Question Graph"
                className="align-middle border-none max-w-full rounded-lg"
                src={require("assets/img/question-graph-interpretation.png")}
              />
            </div>
          </div>

          {optionSelected != null && (
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto z-2">
              <div
                className="relative flex flex-col min-w-0 w-full mb-6 md:mt-36
                text-center">
                {optionSelected === practiceqs[questiono].answer ? (
                  <div className="p-5">
                    <i
                      className="fas fa-check text-2xl"
                      style={{color: "green", fontSize: "4rem"}}
                    />
                    <h6
                      className="font-bold leading-relaxed mt-4 mb-4
                      text-green-700 p-10 text-3xl"
                      style={{color: "green"}}
                    >
                      Nice one!
                    </h6>
                  </div>
                ) : (
                  <div className="p-5">
                    <i
                      className="fas fa-frown-open text-2xl"
                      style={{color: "red", fontSize: "4rem"}}
                    />
                    <h6
                      className="font-bold leading-relaxed mt-4 mb-4 p-10
                      text-3xl"
                      style={{color: "red"}}
                    >
                      Unlucky!
                    </h6>
                    <p className="text-casemath-blue">
                      The correct answer was{" "}
                      {
                        practiceqs[questiono].options[
                          practiceqs[questiono].answer
                          ]
                      }
                    </p>
                  </div>
                )}
                <div>
                  {questiono !== practiceqs.length - 1 && (
                    <button
                      onClick={() => handleQChange()}
                      className="hover:shadow-xl ease-linear transition-all
                      duration-150 focus:outline-none"
                    >
                      <div
                        className="text-casemath-blue text-bold bg-white
                        shadow-md rounded p-5">
                        Next Question{" "}
                        <i className="fas fa-arrow-alt-circle-right"/>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Options A - D */}

          {optionSelected === null && (
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto z-2">
              <div
                className="relative flex flex-col min-w-0 w-full mb-6 md:mt-36">
                <h6
                  className="text-lg font-semibold leading-relaxed mt-4 mb-4
                  text-casemath-blue p-10">
                  {practiceqs[questiono].question}
                </h6>
                <div className="grid grid-cols-2 gap-4">
                  {McqOption(setoptionSelected, questiono, "a")}
                  {McqOption(setoptionSelected, questiono, "b")}
                  {McqOption(setoptionSelected, questiono, "c")}
                  {McqOption(setoptionSelected, questiono, "d")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function McqOption(setOptionSelected, questiono, optionLetter) {
  return (
    <button
      className="shadow-sm focus:outline-none hover:shadow-lg ease-in-out
      transition-all duration-150 bg-white
            rounded-lg border-2 border-white bg-white px-3 flex my-1 py-4
            hover:border-casemath-blue"
      onClick={() => setOptionSelected(optionLetter)}
    >
        <span className="flex text-left flex-grow capitalize">
          <span className="capitalize">{optionLetter}</span>
        </span>
      <span className="text-right flex-grow">
          {practiceqs[questiono].options[optionLetter]}
        </span>
    </button>
  );
}
