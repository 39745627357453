export const STANDALONE_QS =
  `*[_type=="standaloneQuestions" && $topicname in topic[]->title] {
  id,
    "rating": difficulty[]->rating,
    explanation,
    question,
    optiona,
    optionb,
    optionc,
    optiond,
    answera,
    answerb,
    answerc,
    answerd,
    hint1,
    hint2,
    hint3,
}`