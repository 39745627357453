import React from "react";
import Chart from "chart.js";

export default function DiscreteBarGraph(props) {
  React.useEffect(() => {
    let ctx = document.getElementById("bar-chart").getContext("2d");
    window.myBar = new Chart(ctx, config(props));
  }, []);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold">
                Practice question
              </h6>
              <h2 className="text-gray-800 text-xl font-semibold">
                Comparision of x vs y
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="bar-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}

export function setGraphParamsFromQData(labels, randdata, discreteIds) {
  config.data.labels = discreteIds; // length is numQs length
  var datasets = config.data.datasets[0];
  var datasetTemps = [];
  for (let i = 0; i < randdata.length; i++) {
    datasets.data = randdata[i];
    datasets.label = labels[i];
    datasetTemps.push(datasets);
  }
  config.data.datasets = datasetTemps;
}

const config = (props) => {
  return {
    type: "bar",
    data: {
      labels: ["Category1", "Category2", "Category3", "Category4", "Category5"], //Discrete Identifiers
      datasets: [
        {
          label: "Discrete Identifier 1", // Period of Time, e.g. Month or Year
          backgroundColor: "#ed64a6",
          borderColor: "#ed64a6",
          data: [1, 2, 3, 4, 5],
          fill: false,
          barThickness: 8,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      title: {
        display: false,
        text: "Orders Chart",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      legend: {
        labels: {
          fontColor: "rgba(0,0,0,.4)",
        },
        align: "end",
        position: "bottom",
      },
      scales: {
        xAxes: [
          {
            display: false,
            scaleLabel: {
              display: true,
              labelString: "Discrete Categories",
            },
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              color: "rgba(33, 37, 41, 0.3)",
              zeroLineColor: "rgba(33, 37, 41, 0.3)",
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: false,
              labelString: "Value",
            },
            gridLines: {
              borderDash: [2],
              drawBorder: false,
              borderDashOffset: [2],
              color: "rgba(33, 37, 41, 0.2)",
              zeroLineColor: "rgba(33, 37, 41, 0.15)",
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
            },
          },
        ],
      },
    },
  };
};
