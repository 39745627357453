//  Theming
import {ThemeProvider} from "@material-ui/styles";
import React from "react";
import ReactGA from "react-ga";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {casemathTheme} from "../utils/effects/MuiTheme";
import ScrollToTop from "../utils/effects/ScrollToTop";

import GA from "../utils/marketing/GoogleAnalytics";
import {Mixpanel} from "../utils/marketing/Mixpanel";
import Booking from "../views/booking/Booking";

// Views without the layouts
import LandingPage from "../views/landing/LandingPage";
import PrivacyPolicy from "../views/legal/PrivacyPolicy";
import TermsAndConditions from "../views/legal/TermsAndConditions";
import About from "../views/misc/AboutPage/About";
import ContactUs from "../views/misc/ContactUs"
import Leaderboard from "../views/misc/Leaderboard"

// Special Routes with layouts
import Admin from "./Admin";
import Auth from "./Auth";
import Content from "./Content";
import User from "./User";

var Links = require("./Links");

class Routing extends React.Component {
  constructor(props) {
    super(props);
  }

  authenticate() {
    // 2 seconds
    return new Promise((resolve) => setTimeout(resolve, 2000));
  }

  logPageView(nextState, replace) {
    Mixpanel.track(`Changing Page`, {"page": nextState.location.pathname})
  }

  componentDidMount() {
    ReactGA.initialize("G-MLDJ2G655Z");
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({isLoading: false});
    this.authenticate().then(() => {
      const ele = document.getElementById("ipl-progress-indicator");
      if (ele) {
        // fade out
        ele.classList.add("available");
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = "";
        }, 3000);
      }
    });
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={casemathTheme}>
          <BrowserRouter>
            {GA.init() && <GA.RouteTracker/>}
            <ScrollToTop>
              <Switch>
                {/* WITH LAYOUTS */}
                <Route path="/admin" component={Admin}/>
                <Route path="/auth" component={Auth}/>
                <Route path="/practice" component={Content}/>
                <Route path="/user" component={User}/>

                {/* WITHOUT LAYOUTS */}
                <Route path="/" exact component={LandingPage}/>

                {/* Misc */}
                <Route path="/about" exact component={About}/>
                <Route path="/contact" exact component={ContactUs}/>
                <Route path="/leaderboard" exact component={Leaderboard}/>
                <Route path="/courses" exact component={Booking}/>

                {/* Legals */}
                <Route path={Links.TnCs} exact component={TermsAndConditions}/>
                <Route
                  path={Links.PrivacyPolicy}
                  exact
                  component={PrivacyPolicy}
                />

                {/* Redirect for Home Page */}
                <Redirect from="*" to="/"/>
              </Switch>
            </ScrollToTop>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    );
  }
}

export default Routing;
