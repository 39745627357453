import * as PropTypes from "prop-types";
import React from "react";
import SocialAuthentications from "./SocialAuthentications";

const Links = require("layouts/Links");

export default function SignUpForm(props) {
  return <>
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-6/12 px-4">
          <div
            className="relative flex flex-col min-w-0 break-words w-full mb-6
            shadow-lg rounded-lg bg-gray-300 border-0">
            <div className="rounded-t mb-0 px-6 py-6">
              <SocialAuthentications errormsg={props.errormsg}/>
              <hr className="mt-6 border-b-1 border-gray-400"/>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {/*<div className="text-gray-500 text-center mb-3 font-bold">
                <small>Or sign up with credentials</small>
              </div> */}
              <form>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs
                    font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Full Name
                  </label>
                  <input
                    name="fullname"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700
                    bg-white rounded text-sm shadow focus:outline-none
                    focus:shadow-outline w-full ease-linear transition-all
                    duration-150"
                    placeholder="Name"
                    onChange={props.onChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs
                    font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Username
                  </label>
                  <input
                    name="username"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700
                    bg-white rounded text-sm shadow focus:outline-none
                    focus:shadow-outline w-full ease-linear transition-all
                    duration-150"
                    placeholder="Username"
                    onChange={props.onChange}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold
                    mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700
                    bg-white rounded text-sm shadow focus:outline-none
                    focus:shadow-outline w-full ease-linear transition-all
                    duration-150"
                    placeholder="Email"
                    onChange={props.onChange}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs
                    font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password (8 or more characters and a symbol)
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700
                    bg-white rounded text-sm shadow focus:outline-none
                    focus:shadow-outline w-full ease-linear transition-all
                    duration-150"
                    placeholder="Password"
                    onChange={props.onChange}
                  />
                </div>

                <div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      name="agreeprivacypolicy"
                      id="customCheckLogin"
                      type="checkbox"
                      className="form-checkbox text-gray-800 ml-1 w-5 h-5
                      ease-linear transition-all duration-150"
                      onChange={props.onChange}
                    />
                    <span className="ml-2 text-sm font-semibold text-gray-700">
                            I agree with the{" "}
                      <a
                        href={Links.PrivacyPolicy}
                        className="text-blue-500"
                        onClick={props.onClick}
                      >
                              Privacy Policy
                            </a>
                          </span>
                  </label>
                </div>

                <div className="text-center mt-6">
                  <button
                    className="bg-gray-900 text-white active:bg-gray-700 text-sm
                     font-bold uppercase px-6 py-3 rounded shadow
                     hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
                     w-full ease-linear transition-all duration-150"
                    type="button"
                    onClick={props.onClick1}
                  >
                    Create Account
                  </button>
                </div>
              </form>
              <div>
                <span
                  className="ml-2 text-sm font-semibold text-gray-700">
                  Already have an account?{" "}
                  <button
                    className="text-blue-500"
                    onClick={props.onClick2}
                  >
                    Sign In
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}

SignUpForm.propTypes = {
  errormsg: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onClick1: PropTypes.func,
  onClick2: PropTypes.func,
};