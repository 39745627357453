import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// MaterialUI
import {makeStyles} from "@material-ui/core/styles";
import Footer from "components/Footers/Footer";

// Components
import IndexNavbar from "components/Navbars/IndexNavbar";
import {client} from "queries/client";

// sanityio
import {TEASER_QS} from "queries/teaserqs";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {serializer} from "utils/effects/serializer";
import {Mixpanel} from "../../../utils/marketing/Mixpanel";

const BlockContent = require("@sanity/block-content-to-react");

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

export default function BrainteaserUI() {
  const classes = useStyles();
  const [questions, setAllQuestions] = useState(null);
  const [count, setCount] = useState(0);
  const [answermode, setAnswermode] = useState(false);

  Mixpanel.track("Entered Brainteasers Page")

  async function getQuestions() {
    const params = {topicname: "Brainteasers"};
    await client
      .fetch(TEASER_QS, params)
      .then((data) => {
        console.log("incoming data: ", data);
        setAllQuestions(data);
      })
      .then(console.log(questions))
      .catch((err) => console.log("ERROR WITH GROQ REQUEST:", err));

    console.log("Run Through complete");
  }

  useEffect(() => {
    console.log("running");
    getQuestions();
  }, []);

  const handleNext = () => {
    if (!answermode) {
      setAnswermode(true);
    } else {
      setAnswermode(false);
      setCount(count + 1);
    }
  };

  const handlePrev = () => {
    if (answermode) {
      setAnswermode(false);
    } else {
      setCount(count - 1);
    }
  };

  return (
    <div className="h-screen w-fullscreen">
      <IndexNavbar fixed/>
      <div className={classes.heroContent}>
        <Container maxWidth="md">
          <div
            className="mx-10 bg-white rounded-xl shadow-xl justify-center relative mt-10 h-screen-50 flex flex-col">
            {/* <button className="absolute top-0 right-0 p-4 text-sm">Skip</button> */}
            <div className="text-center top-0 p-5 text-2xl">
              <h1 className="text-casemath-blue">
                {questions !== null && questions.length !== 0 ? (
                  <span>
                    Question {Math.min(count + 1, questions.length)} of{" "}
                    {questions.length}
                  </span>
                ) : (
                  <span>That's it!</span>
                )}
              </h1>
            </div>
            <div className="text-center p-5 text-xl my-auto">
              <h1>
                {questions !== null && count < questions.length ? (
                  <div>
                    {answermode ? (
                      <BlockContent
                        blocks={questions[count].explanation}
                        serializers={serializer}
                      />
                    ) : (
                      <BlockContent
                        blocks={questions[count].question}
                        serializers={serializer}
                      />
                    )}
                  </div>
                ) : (
                  <p>That's all we have for now - check back soon!</p>
                )}
              </h1>
            </div>
          </div>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              {/* Previous Button */}
              {(count > 0 || answermode) && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handlePrev()}
                  >
                    Prev
                  </Button>
                </Grid>
              )}

              {/* Next Button */}
              {questions !== null && count < questions.length && (
                <Grid item>
                  <Button
                    variant={answermode ? "contained" : "outlined"}
                    color={answermode ? "primary" : "secondary"}
                    onClick={() => handleNext()}
                  >
                    {answermode ? "Next" : "See answer"}
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} justify="center">
              {/*Back button */}
              <Grid item>
                <Link to="/practice">
                  <Button
                    variant={"outlined"}
                    color={"info"} // todo fix
                  >
                    Back to topics
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
