import Footer from "components/Footers/Footer"
import IndexNavbar from "components/Navbars/IndexNavbar"
import React from 'react';
import {Provider} from "react-redux";

import './components/MentalMaths.css'

import MentalMathsUI from './components/MentalMathsUI'
import store from "./components/store";

export default function App() {
  return (
    <div>
      <IndexNavbar fixed/>
      <Provider store={store}>
        <MentalMathsUI/>
      </Provider>
      <Footer/>
    </div>
  );
};
