import {Button} from "@material-ui/core"
import React, {PureComponent, useState} from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {Field, reduxForm} from "redux-form";
import seedRandom from "seed-random";
import Calculator from "../../calculator/Calculator";
import seed from "./react-seed-wrapper";
import sequence from "./react-sequence-wrapper";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

const isRequired = value => {
  return value !== undefined ? undefined : "Answer is required";
};

const isCorrect = (value, allValues, props) => {
  const {currentStep} = props;

  //TODO: Change to allow other types of operations as well.
  const answer = currentStep.a + currentStep.b;
  return parseInt(value, 10) === answer ? undefined : "incorrect";
};

const int100 = decimal => Math.floor(decimal * 100); // ex. transforms .25 to 25

@seed
@sequence({
  steps: (index, props) => {
    const rng = seedRandom(index + props.seed);

    const a = int100(rng());
    const b = int100(rng());
    return {a, b};
  },
})
@reduxForm({
  form: "question",
})

class MentalMathsUI extends PureComponent {
  submit = () => {
    const {reset, goToNext} = this.props;
    reset();
    goToNext();
  };

  restart = () => {
    const {reseed, resequence} = this.props;
    reseed();
    resequence();
  };

  render() {
    const {
      handleSubmit,
      invalid,
      anyTouched, //from @reduxForm mixin

      currentIndex,
      farthestIndex,
      currentStep,
      goToNext,
      goToPrev,
      goToIndex,
      goToStep,
      isFirstStep,
      // isLastStep, //from @sequence mixin
    } = this.props;

    return (
      <div className={"py-12 mx-auto text-center flex flex-col"}>
        <div className={""}>

          <h2>
            <label className={"text-center text-4xl text-casemath-blue " +
            "text-bold"}>
              Question #{currentIndex + 1}
            </label>
          </h2>


        </div>

        {currentIndex === farthestIndex ? (
          <form onSubmit={handleSubmit(this.submit)}>
            <div className={"text-4xl p-4 pt-8"}>
              {`${currentStep.a} + ${currentStep.b} =`}
              <Field
                name="answer"
                className={"text-4xl p-4 pt-8"}
                validate={[isRequired, isCorrect]}
                component={AnswerField}
              />
            </div>

            <div className={"text-xl pt-2"}>
              <Button onClick={goToPrev} color="secondary"
                      variant={"primary"} disabled={isFirstStep}>
                Back
              </Button>
              <Button
                type="submit"
                color="primary"
                variant={"primary"}
                disabled={
                  currentIndex === farthestIndex && (anyTouched && invalid)
                }
              >
                Next
              </Button>
            </div>
          </form>
        ) : (
          <div>
            <div className={"text-4xl p-4 pt-8"}>
              {`${currentStep.a} + ${currentStep.b} = `}

              <span>
                {`${currentStep.a + currentStep.b}`}
              </span>
            </div>
            <div className={"text-xl pt-2"}>
              <Button onClick={goToPrev} disabled={isFirstStep}
                      color={"secondary"} variant={"contained"}>
                Back
              </Button>
              <Button color="primary" variant="outlined" onClick={goToNext}>
                Next
              </Button>
            </div>
          </div>
        )}

        <div>
          <h5 className={"text-casemath-blue text-semibold text-2xl mt-4"}>
            Questions
          </h5>
          <div className={"m-2"}>
            {Array(farthestIndex + 1)
              .fill()
              .map((step, index) => (
                <Button
                  key={index}
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => goToIndex(index)}
                  disabled={currentIndex === index}
                >
                  #{index + 1}
                </Button>
              ))}
          </div>
        </div>

        <div className={"text-xl pt-2"}>
          <Button
            onClick={this.restart}
            variant={"contained"}
            color={"primary"}
            disabled={farthestIndex === 0}
          >
            Restart
          </Button>
        </div>
        <div className={"text-xs my-5"}>
          <Sidebar/>
        </div>
      </div>
    );
  }
}

const
  AnswerField = ({input, meta: {touched, error, warning}}) => (
    <span>
    <input
      {...input}
      autoComplete="off"
      className="answer border-2 border-casemath-blue outline-none"
      placeholder="?"
      type="text"
      maxlength="3"
    />
    <div className={error ? "error" : "success"}>
      {touched && (error ? error : "correct!")}
    </div>
  </span>
  );

const Sidebar = () => {
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  return (
    <div>
      <Button className="text-sm" variant={"outlined"} color={"secondary"}
              onClick={() => setState({isPaneOpen: true})}>
        I really need a calculator
      </Button>
      <Grid container spacing={2} justify="center">
              {/*Back button */}
              <Grid item>
                <Link to="/practice">
                  <Button
                    variant={"outlined"}
                    color={"info"} // todo fix
                  >
                    Back to topics
                  </Button>
                </Link>
              </Grid>
            </Grid>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={state.isPaneOpen}
        title="Calculator"
        subtitle="No worries. It happens to all of us sometimes."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({isPaneOpen: false});
        }}
      >
        <Calculator/>
      </SlidingPane>
      <SlidingPane
        closeIcon={<div>Some div containing custom close icon.</div>}
        isOpen={state.isPaneOpenLeft}
        title="Hey, it is optional pane title.  I can be React component too."
        from="left"
        width="200px"
        onRequestClose={() => setState({isPaneOpenLeft: false})}
      >
        <Calculator/>
      </SlidingPane>
    </div>
  );
};

export default MentalMathsUI;