export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      firstname
      lastname
      email
      dob
      university
      city
      country
      levelofrole
      interestedroles
      _lastChangedAt
      updatedAt
    }
  }
`;