import Rating from '@material-ui/lab/Rating';
import BlockContent from '@sanity/block-content-to-react';
import Footer from "components/Footers/Footer"
import IndexNavbar from "components/Navbars/IndexNavbar"
import React, {useEffect, useState} from "react";
import {client} from "../../../queries/client";
import {STANDALONE_QS} from "../../../queries/standaloneqs";
import ConvertSanitytoQuestionFormat
  from "../../../questionEngine/ConvertSanitytoQuestionFormat";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";



import {serializer} from "../../../utils/effects/serializer";

export default function StandaloneQuestions(props) {
  let topic = "General - new!"
  const [questiono, setquestiono] = useState(0);
  const [optionSelected, setOptionSelected] = useState(null);
  const [practiceqs, setPracticeqs] = useState([]);

  useEffect(() => {
    client
      .fetch(STANDALONE_QS, {topicname: topic})
      .then((data) => {
        let processedQuestions = ConvertSanitytoQuestionFormat(data)
        setPracticeqs(processedQuestions)
      })
      .catch((err) => console.log("ERROR WITH GROQ REQUEST:", err));
  }, [topic])

  const handleQChange = () => {
    setquestiono(questiono + 1);
    setOptionSelected(null);
  };

  const getAnswer = () => {
    let q = practiceqs[questiono]
    let answer = q.options[q.answer]
    return <BlockContent blocks={answer} serializers={serializer}/>
  }

  const getQuestion = () => {
    let q = practiceqs[questiono].question
    return <BlockContent blocks={q} serializers={serializer}/>
  }

  const getOption = (letter) => {
    let o = practiceqs[questiono].options[letter]
    return <BlockContent blocks={o} serializers={serializer}/>
  }

  const getExplanation = () => {
    let e = practiceqs[questiono].explanation
    return <BlockContent blocks={e} serializers={serializer}/>
  }

  const getImage = () => {
    return <div
      className="w-full mx-4 md:w-6/12 ml-auto mr-auto mt-10
            bg-white shadow-2xl rounded">
      <div className="flex-col">
        <h3
          className="text-lg font-semibold leading-relaxed mt-4 mb-4
                text-casemath-blue text-center">
          Practice Questions
        </h3>

        <img
          alt="Question Graph"
          className="align-middle border-none max-w-full rounded-lg"
          src={require("assets/img/question-graph-interpretation.png")}
        />
      </div>
    </div>
  }

  const SuccessFailureView = () => {

    const getRating = () => {
      if (practiceqs[questiono].rating) {
        return (
          <div className={"py-10 flex flex-row"}>
            <p className={"text-md font-bold"}>Question Difficulty:</p>
            <Rating name="read-only" value={practiceqs[questiono].rating}
                    readOnly/>
          </div>
        )
      } else {
        return <div/>
      }
    }
    const SuccessView = () => {
      return (
        <div className="p-5">
          {getRating()}
          <h6
            className="font-bold leading-relaxed mt-4 mb-4
                      text-green-700 p-10 text-3xl"
            style={{color: "green"}}
          >
            Correct!
          </h6>
          <div
            className={"bg-gray-100 font-semibold shadow-xl p-10 rounded-xl"}>
            <p>{getExplanation()}</p>
          </div>
        </div>)
    }
    const FailureView = () => {
      return (
        <div className="p-5">
          {getRating()}
          <h6
            className="font-bold leading-relaxed text-3xl"
            style={{color: "red"}}
          >
            The correct answer was{" "}
            {getAnswer()}
          </h6>

          <br/>
          <div
            className={"bg-gray-100 font-semibold shadow-xl p-10 rounded-xl"}>
            {getExplanation()}
          </div>
        </div>
      )
    }
    const rightWrongQnA = () => {
      return (
        <div className="w-full px-4 mr-auto ml-auto z-2 border-1 border-black">
          <div
            className="relative min-w-0 mb-6 md:mt-36">

            <h6 className="text-lg font-semibold leading-relaxed mt-4 mb-4
                text-casemath-blue p-10 text-center">
              {getQuestion()}
            </h6>
            <div
              className="grid grid-cols-2 gap-4">
              {McqOptionAnswer(optionSelected, answer, getOption("a"), "a")}
              {McqOptionAnswer(optionSelected, answer, getOption("b"), "b")}
              {McqOptionAnswer(optionSelected, answer, getOption("c"), "c")}
              {McqOptionAnswer(optionSelected, answer, getOption("d"), "d")}
            </div>
          </div>
        </div>)

    }

    let view = optionSelected === practiceqs[questiono].answer ?
      <SuccessView/> : <FailureView/>

    let answer = practiceqs[questiono].answer

    return (
      <div>
        {optionSelected != null && (
          <div className="px-4 mr-auto ml-auto">
            <div
              className="flex flex-col mb-6 md:mt-36
                text-center">
              {view}
              <div>
                {questiono !== practiceqs.length - 1 && (
                  <button
                    className="text-white font-bold px-6 py-4 rounded
                       outline-none focus:outline-none mr-1 mb-1 bg-pink-400
                       active:bg-pink-200 uppercase text-sm shadow
                       hover:shadow-lg ease-linear transition-all duration-150"
                    onClick={() => handleQChange()}
                  >
                    Next Question
                  </button>
                )}
                {rightWrongQnA()}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <IndexNavbar fixed/>
      {practiceqs.length > 0 && <div>
        <div className="container mx-auto py-10">
          <div className="text-center items-center">
            <h3
              className="text-5xl mb-2 mt-2 font-bold leading-normal
            text-casemath-blue">
              {topic} Practice Questions
            </h3>
          </div>
          <div className="flex flex-wrap items-center">
            <SuccessFailureView/>

            {/* Options A - D */}
            {optionSelected === null && (
              <div className="w-full px-4 mr-auto ml-auto z-2">
                <div
                  className="relative min-w-0 mb-6 md:mt-36">

                  <h6 className="text-lg font-semibold leading-relaxed mt-4 mb-4
                text-casemath-blue p-10 text-center">
                    {getQuestion()}
                  </h6>
                  <div
                    className="grid grid-cols-2 gap-4">
                    {McqOption(setOptionSelected, getOption("a"), "a")}
                    {McqOption(setOptionSelected, getOption("b"), "b")}
                    {McqOption(setOptionSelected, getOption("c"), "c")}
                    {McqOption(setOptionSelected, getOption("d"), "d")}
                  </div>
                </div>
              </div>)}
          </div>
          <Grid container spacing={2} justify="center">
              {/*Back button */}
              <Grid item>
                <Link to="/practice">
                  <Button
                    variant={"outlined"}
                    color={"info"} // todo fix
                  >
                    Back to topics
                  </Button>
                </Link>
              </Grid>
            </Grid>
        </div>
      </div>}
      <Footer/>
    </div>
  )
}

function McqOption(setOptionSelected, getOption, optionLetter) {
  return (
    <button
      className="shadow-sm focus:outline-none hover:shadow-lg ease-in-out
      transition-all duration-150 bg-white
            rounded-lg border-2 border-gray-300 bg-gray-100 px-3 flex my-1 py-4
            hover:border-casemath-blue shadow-3xl"
      onClick={() => setOptionSelected(optionLetter)}>
        <span className="flex text-left flex-grow capitalize">
          <span className="capitalize">{optionLetter}</span>
        </span>
      <span className="text-right flex-grow">
          {getOption}
        </span>
    </button>
  );
}

function McqOptionAnswer(optionSelected, answer, getOption, optionLetter) {

  const getBorderColor = () => {
    if (optionSelected === optionLetter) {
      return (optionSelected === answer) ? "border-green-600" :
        "border-red-600"
    }
    return ""
  }

  return (
    <button
      disabled
      className={`shadow-sm focus:outline-none hover:shadow-lg ease-in-out 
        transition-all duration-150 rounded-lg bg-gray-100 border-4 px-3 flex 
        py-4 my-2 w-full ${getBorderColor()}`}
    >
        <span className="flex text-left flex-grow capitalize">
          <span className="capitalize">{optionLetter}</span>
        </span>
      <span className="text-right flex-grow">
          {getOption}
        </span>
    </button>
  );
}