import API from "@aws-amplify/api";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {apiName, myInit, path} from "../../Tests/PracticePage";
import {answersQuestionUI} from "./AnswerView/answersQuestionUI";
import {beginQuestionUI} from "./BeginView/beginQuestionUI";
import {headerView} from "./Header/HeaderView";
import {questionsQuestionUI} from "./QuestionView/questionsQuestionUI";
import {timesupQuestionUI} from "./TimesUpView/timesupQuestionUI";

export function QuestionPlatformUI(props) {
  const history = useHistory()
  const [hide, setHide] = useState(true); // For displaying hints
  const hintAllowed = props.hintAllowed;
  const practiceqs = props.practiceqs;

  const [randnums, setRandnums] = useState({
    // Temp
    Data: [
      [90.74626, 110.22798, 97.94625, 100.163895, 93.33578],
      [96.21283, 93.81293, 88.59721, 90.73264, 93.87218],
      [56.083763, 56.02001, 55.927834, 56.87598, 56.867233],
    ],
    Outliers: [1, 0],
  });

  useEffect(() => {
    if (props.minutes <= 0 && props.seconds === 0) handleView("timesup");
  });

  /* Warn before navigating away from the page */
  window.addEventListener('beforeunload',
    (event) => {
      if (window.location.href.indexOf("tests") > -1) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = "If you leave this page you will " +
          "lose your unsaved changes." +
          " Are you sure you wish to proceed?";
      }
    });

  const handleView = props.handleView;
  const view = props.view;

  const [choices, setChoices] = useState({});
  const [questiono, setquestiono] = useState(0);

  /* Analytics */
  const [totalTimes, updateTotalTimes] = useState({});
  const [lastRecordedTime, setLastRecordedTime] = useState(new Date());

  /* On changing question, change which question we are recording */
  async function setQuestionNum(val) {
    await getNewTime();
    setquestiono(val)
  }

  /* Measure time taken on a particular question */
  async function getNewTime() {
    if (view !== "questions") {
      return;
    }
    let currTime = new Date();
    let newTime = Math.abs((currTime - lastRecordedTime))
    setLastRecordedTime(currTime)
    if (totalTimes[questiono + 1]) {
      newTime += totalTimes[questiono + 1]
    }
    let index = (questiono + 1).toString()
    updateTotalTimes(totalTimes => ({
      ...totalTimes,
      [index]: newTime,
    }))
  }

  /* Get accuracy */
  const getAccuracies = () => {
    let marks = 0;
    practiceqs.forEach((r, index) => {
      if (choices[index] && r.answer && r.answer === choices[index]) {
        marks += 1;
      }
    });
    console.log("marks: " + marks);
    const percentage = ((marks / practiceqs.length) * 100)
      .toFixed(2);
    return `${percentage}%`;
  };

  /* Change the question to be viewed */
  async function handleQChange() {
    await getNewTime()
    setQuestionNum(questiono + 1);
  }

  // TODO: generate numbers from API here to make question graph
  const generateNums = async () => {
    const {setGraphParamsFromQData} = require("../Graphs/BarPie");
    await API.get(apiName, path, myInit)
      .then((res) => res.json())
      .then((r) => setRandnums(r))
      .then((r) => {
        console.log(r);
        setGraphParamsFromQData(
          "Time Label here",
          r.Data,
          "Discrete Identifier",
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  /* Header of the QuestionUI */
  const Header = headerView(
    props,
    view,
    practiceqs,
    setQuestionNum,
    questiono,
    choices,
  );

  switch (view) {
    case "begin":
      return beginQuestionUI(handleView, props);
      break
    case "questions":
      return questionsQuestionUI(
        Header,
        questiono,
        practiceqs,
        choices,
        setChoices,
        handleQChange,
        hintAllowed,
        setHide,
        hide,
        setQuestionNum,
        handleView,
      );
      break;
    case "timesup":
      return timesupQuestionUI(handleView);
    case "answers":
      if (practiceqs.length === 0) {
        history.push("/practice")
      }

      return answersQuestionUI(
        Header,
        questiono,
        practiceqs,
        choices,
        setChoices,
        handleQChange,
        setHide,
        hide,
        getAccuracies,
        setQuestionNum,
        totalTimes,
      );
  }
}


