import React from "react";

/* -------------------------------------------------------------------------- */
/*                             Sample Logo Mockup                             */
/* -------------------------------------------------------------------------- */

export default function Logo() {
  return (

    <img
      alt="..."
      className="content-center border-none rounded-lg block mx-auto"
      src={require("assets/img/casemath-logo.png")}
      width="200"
      height="40"
    />

  );
}
