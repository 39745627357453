import React from "react";
import {
  useStripe,
  useElements,
  CardElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { Button } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Container, Grid, FormControl, FormLabel } from "@material-ui/core";
import "./PayByStripe.css";

const stripePromise = loadStripe("pk_test_35p114pH8oNuHX72SmrvsFqh00Azv3ZaIA");

export default function PayByStripe(props) {
  return (
    <div className="">
      <div className="items-center text-center flex flex-col">
        <img
          src={require("assets/img/casemath-logo.png")}
          alt="laptop"
          style={{ width: "20%", height: "auto" }}
        />
        <div>
          <Elements stripe={stripePromise}>
            <CheckoutForm price={props.price} tier={props.tier} />
          </Elements>
        </div>
      </div>
    </div>
  );
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <label>
      Card details
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
}

class CheckoutForm extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result.token);
    }
  };

  render() {
    return (
      <div>
        <div class="product-info">
          <h3 className="product-title">{this.props.tier} Session</h3>
          <h4 className="product-price">{this.props.price}</h4>
        </div>
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <button className="btn-pay">Pay Now</button>
        </form>
      </div>
    );
  }
}

function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
