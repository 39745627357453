import {Auth} from "aws-amplify";
import mixpanel from "mixpanel-browser";

let actions = {
  identify: (id) => {
    mixpanel.identify(id)
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    Auth.currentAuthenticatedUser().then(user => {
      mixpanel.identify(user.attributes.email)
    })
    mixpanel.track(name, props)
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;