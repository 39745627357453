import React from "react";
import {useHistory} from "react-router-dom"

// Material UI
import Button from "@material-ui/core/Button";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "components/Footers/Footer.js";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}))

//TODO: APIify
const tests = [
    {
        title: "Data analysis 1",
        img: "",
        description:
            "17 questions",
        slug: 1,
    },
    {
        title: "Data analysis 2",
        img: "",
        description:
            "15 questions",
        slug: 2,
    },
    {
        title: "Data analysis 3",
        img: "",
        description:
            "15 questions",
        slug: 3,
    },
    {
        title: "Data analysis 4",
        img: "",
        description:
            "15 questions",
        slug: 4,
    },
    {
        title: "Table analysis 1",
        img: "",
        description:
            "10 questions",
        slug: 5,
    },
    {
        title: "Table analysis 2",
        img: "",
        description:
            "9 questions",
        slug: 6,
    },
];

export default function TopicsPage() {
    const history = useHistory();
    const classes = useStyles();

    const randomRouteChange = () => {
        const getRandomArbitrary = (min, max) => {
            return Math.floor(Math.random() * (max - min) + min);
        };

        const n = getRandomArbitrary(1, 6);
        routeChange(n);
    };

    const routeChange = (url) => {
        history.push(`/practice/topics/tests/aptitudetest/${url}`);
    };

    return (
        <React.Fragment>
            <IndexNavbar fixed/>
            <CssBaseline/>
            <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container maxWidth="m">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="primary"
                            gutterBottom
                        >
                            Practice tests
                        </Typography>
                        <Typography
                            variant="h5"
                            align="center"
                            color="textSecondary"
                            paragraph
                        >
                            Take a mock test to see how you're doing - try a sample or choose one below
                        </Typography>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="center">
                                {/*<Grid item>
                  <Button variant="contained" color="primary">
                    Choose randomly
                  </Button>
                </Grid>*/}
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => randomRouteChange()}
                                    >
                                        Try a short sample
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Link to="/practice">
                                    <Button
                                        variant={"outlined"}
                                        color={"info"} // todo fix
                                    >
                                        Back to topics
                                    </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {tests &&
                        tests.map((t) => (
                            <Grid item key={t.title} xs={12} sm={6} md={4}>
                                <Card className={classes.card}>
                                    {/* <CardMedia
                                        className={classes.cardMedia}
                                        image={t.img}
                                        title={t.title}
                                    /> remove empty space reserved for images, maybe add images later*/}
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {t.title}
                                        </Typography>
                                        <Typography>{t.description}</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="large"
                                            color="primary"
                                            onClick={() => routeChange(t.slug)}
                                        >
                                            Start now
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <Footer/>
            {/* End footer */}
        </React.Fragment>
    );
}
