import React from "react";

export function timesupQuestionUI(handleView) {
  return (
    <div>
      <div className="h-screen w-fullscreen">
        <nav
          className="top-0 z-50 w-full flex flex-wrap items-center
          justify-between px-2 py-1 navbar-expand-lg bg-gray-800 shadow
          text-white">
          <div
            className="container px-12 mx-auto flex flex-wrap items-center
            justify-between">
            <div
              className="w-full relative flex justify-between lg:w-auto
              lg:static lg:block lg:justify-start">
              <div
                className="text-gray-800 text-xl font-bold leading-relaxed
                inline-block mr-4 py-2 whitespace-no-wrap uppercase">
                PRACTICE TEST
              </div>
            </div>
          </div>
        </nav>

        <div className="flex flex-row">
          <div className="w-full bg-white md:w-2/3 lg:w-2/3 xl:w-2/3">
            <div className="m-8 h-full">
              <h1
                className="text-casemath-blue text-4xl font-semibold text-center
                 justify-center items-center overflow-hidden">
                Great work!
              </h1>
            </div>
          </div>

          <div
            className="bg-gray-200 w-full md:w-1/4 lg:w-1/3 xl:w-1/3 p-8
            shadow-xl h-screen overflow-hidden">
            <div
              className="container px-12 mx-auto flex flex-wrap flex-col
              items-center justify-between">
              <div
                className="w-full relative flex flex-col hover:justify-between
                lg:w-auto lg:static lg:block lg:justify-start space-between
                align-middle items-center text-casemath-blue text-semibold">
                <br></br>
                <span className="font-bold text-4xl">Time's up!</span>
                <br/>
                <br/>
                <span className="font-bold">
                  Click the button to see your results
                </span>
              </div>
              <br/>
              <div
                onClick={() => {
                  handleView("answers");
                }}
                className="text-white font-bold px-6 py-4 rounded outline-none
                focus:outline-none mr-1 mb-1 bg-casemath-blue active:bg-blue-600
                 uppercase text-sm shadow hover:shadow-lg ease-linear
                 transition-all duration-150"
              >
                Proceed
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
