/* -------------------------------------------------------------------------- */
/*                      Control all hyperlinks on website                     */
/* -------------------------------------------------------------------------- */

export const Home = "/"
export const Landing = "/coming-soon";
export const Profile = "/profile";
export const Admin = "/coming-soon";

/* Auth */
export const Auth = "/coming-soon";
export const AuthLogin = "/auth/form/login"
export const AuthRegister = "/auth/form/register"

export const AboutUs = "/about";
export const Team = "/team";
export const Services = "/coming-soon";
export const Pricing = "/coming-soon";
export const Copyright = "/coming-soon";
export const TnCs = "/terms-and-conditions";
export const PrivacyPolicy = "/privacy-policy";
export const ContactUs = "/contact";
export const Blog = "/coming-soon";
export const Courses = "/courses";
export const Practice = "/practice"