export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      firstname
      lastname
      email
      dob
      university
      city
      country
      levelofrole
      interestedroles
    }
  }
`;