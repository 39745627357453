import React from "react";
import {Link} from "react-router-dom";
import Canvas from "utils/effects/ParticlesCanvas";

const Links = require("../../../layouts/Links");

/* -------------------------------------------------------------------------- */
/*   Landing Page Top Section showing initial text and mockup video example   */
/* -------------------------------------------------------------------------- */

export default function Header() {
  return (
    <div>
      <Canvas className="absolute"/>
      <div
        className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">

        <div className="flex flex-col items-center justify-between lg:flex-row">
          <div className="mb-10 lg:max-w-lg lg:pr-5 lg:mb-0">
            <div className="max-w-xl mb-6">
              <div>
                <p
                  className="inline-block px-3 py-px mb-4 text-xs font-semibold
                tracking-wider text-teal-900 uppercase rounded-full
                bg-teal-accent-400">
                  {/* Brand new */}
                </p>
              </div>
              <h2
                className="text-4xl max-w-lg mb-6 font-sans font-bold
              tracking-tight sm:text-4xl sm:leading-none text-casemath-blue">
                Ace your job applications with 10 mins of prep a day{" "}
                <span className="inline-block text-deep-purple-accent-400">
                {/* a lazy dog */}
              </span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Casemath develops your analytical skills for online assessments
                and interviews, maximising your chances to get the role you
                want.
              </p>
            </div>
            <div className="flex flex-col items-center md:flex-row">
              <Link
                to={Links.AuthRegister}
                className="text-white font-bold px-6 py-4 rounded outline-none
              focus:outline-none mr-1 mb-1 active:bg-gray-700 uppercase text-sm
              shadow hover:shadow-xl ease-linear transition-all duration-150
              bg-casemath-blue z-50"
                smooth="true"
                offset={50}
                duration={500}
                // onClick={() => handleScrollToForm()}
              >
                Get started free
              </Link>
            </div>
          </div>
          <div className="relative lg:w-1/2">
            {/* <img
             className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
             src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
             alt=""
             /> */}
            <video width="640" height="540" autoPlay muted loop
                   className="z-50">
              {" "}
              {/* Chrome 66 onwards only allows autoplay if muted */}
              <source src={require("assets/videos/demo.mp4")} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
            {/* <a
             href="/"
             aria-label="Play Video"
             className="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-50 group hover:bg-opacity-25"
             >
             <div className="flex items-center justify-center w-16 h-16 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
             <svg
             className="w-10 text-gray-900"
             fill="currentColor"
             viewBox="0 0 24 24"
             >
             <path d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z" />
             </svg>
             </div>
             </a> */}
          </div>
        </div>
        <Feature/>
      </div>
    </div>
  );
}

const Feature = () => {
  return (
    <div
      className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div
        className="grid grid-cols-2 gap-5 row-gap-5 sm:grid-cols-3 lg:grid-cols-3">
        <FeatureItem
          title="Unlimited questions"
          subtitle="Practice as much as you want"
          icon="fas fa-infinity text-3xl text-casemath-blue"
        />
        <FeatureItem
          title="Interactive lessons"
          subtitle="Learn from successful applicants"
          icon="far fa-bookmark text-3xl text-casemath-blue"
        />
        <FeatureItem
          title="Personalised feedback"
          subtitle="No wasting time finding your mistake"
          icon="fab fa-searchengin text-3xl text-casemath-blue"
        />
      </div>
    </div>
  );
};

const FeatureItem = ({title, subtitle, icon}) => {
  return (
    <div className="text-center">
      <div
        className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
        <i className={icon}></i>
      </div>
      <h6
        className="text-3xl mb-2 mt-2 font-semibold text-casemath-blue leading-normal">
        {title}
      </h6>
      <div className="text-xl leading-relaxed mt-4 mb-2 text-gray-700">
        {subtitle}
      </div>
    </div>
  );
};
