import React from "react";

export default function Content() {
  return (
    <div
      className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl
       md:px-24 lg:px-8 lg:py-20">
      <div
        className="flex items-center justify-center w-12 h-12 mb-4 rounded-full
        bg-casemath-blue lg:w-20 lg:h-20">
        <svg
          className="w-10 h-10 text-white lg:w-16 lg:h-16"
          stroke="currentColor"
          viewBox="0 0 52 52"
        >
          <polygon
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            points="29 13 14 29 25 29 23 39 38 23 27 23"
          />
        </svg>
      </div>
      <div className="flex flex-col lg:flex-row align-top">
        <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
          <h2
            className="mb-5 font-sans text-3xl font-bold tracking-tight
            text-casemath-blue sm:text-4xl sm:leading-none">
            Our mission is to build a brighter future through superior
            adult education
          </h2>
        </div>
        <div className="lg:w-1/2">
          <h1 className={"text-3xl text-bold text-casemath-blue"}>By Students,
            For Students</h1>
          <p className="text-base text-gray-700">
            Getting a job is tough, let alone in the current market. We
            struggled to find helpful resources that were affordable and
            effective. We don't want you to have the same problem. That's
            why we built Casemath. We're passionate about helping our users
            develop the skills they need to kickstart the career of their
            dreams.
          </p>
        </div>
      </div>
    </div>
  );
};