import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import Canvas from "utils/effects/ParticlesCanvas";
import {Mixpanel} from "../../../utils/marketing/Mixpanel";
import NewAboutUs from "./AboutCasemath";
import Team from "./Team";

export default function AboutCasemath() {

  Mixpanel.track("Entered About Page")

  return (
    <div>
      <IndexNavbar fixed/>
      <Canvas/>
      <NewAboutUs/>
      <Team/>
      <Footer/>
    </div>
  );
}