import {API, Auth, Hub} from "aws-amplify";
import mixpanel from 'mixpanel-browser';
import React, {useEffect, useState} from "react";

import {Redirect, useHistory, useLocation, useParams} from "react-router-dom";
import * as mutations from "../../graphql/mutations";
import {Mixpanel} from "../../utils/marketing/Mixpanel";
import ConfirmSignUpForm from "./components/ConfirmSignUpForm";

// Components
import SignInForm from "./components/SignInForm";
import SignUpForm from "./components/SignUpForm";

export default function Login(props) {

  Mixpanel.track("Entering Login Page")

  const {authmode} = useParams();
  const initialFormState = {
    fullname: "",
    username: "",
    password: "",
    rememberme: "",
    formtype: authmode === "register" ? "signUp" : "signIn",
    authcode: "",
    agreeprivacypolicy: false,
  };

  const [formstate, updateFormstate] = useState(initialFormState);
  const [user, updateUser] = useState(false);
  const [errorMessage, displayErrorMessage] = useState("");

  const {formtype} = formstate;
  let history = useHistory();
  let location = useLocation();

  function setErrormsg(msg) {
    displayErrorMessage(msg)
    mixpanel.track("Auth error event", {"error": msg})
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      updateFormstate(() => ({...formstate, formtype: "signedIn"}))
      updateUser(user)
      history.replace("/profile")
    }).catch(err => {
      updateUser(null)
      console.log(err)
    })

    setAuthListener();
  }, []);

  async function setAuthListener() {
    Hub.listen("auth", (data) => {
      const {payload} = data;

      if (payload.event === "signIn") {
        console.log("a user has signed in!");
      }
      if (payload.event === "signOut") {
        mixpanel.track("a user has signed out!");
        updateFormstate(() => ({...formstate, formtype: "signIn"}));
      }
      if (payload.event === "signIn_failure") {
        console.log("Error recognized");
        setErrormsg(payload.data.message);
      }
      if (payload.event === "signUp_failure") {
        console.log("Error recognized");
        setErrormsg(payload.data.message);
      }
    });
  }

  async function signUp() {
    const {
      username,
      email,
      password,
      agreeprivacypolicy,
      fullname,
    } = formstate;

    if (!agreeprivacypolicy) {
      setErrormsg("To sign up please accept our privacy policy");
      return;
    } else if (!username) {
      setErrormsg("To sign up please enter a username");
      return;
    } else if (!email) {
      setErrormsg("To sign up please enter an email");
      return;
    } else if (!fullname) {
      setErrormsg("To sign up please enter your name");
      return;
    } else if (!password) {
      setErrormsg("To sign up please enter a password");
      return;
    }

    const userDetails = {
      firstname: fullname.split(" ")[0],
      lastname: fullname.split(" ")[1] || "",
      email: email,
      username: username,
    };

    await Auth.signUp({
      username,
      password,
      attributes: {
        email,
      },
    }).then(u => {
      console.log(u)
      API.graphql({
        query: mutations.createUser,
        variables: {input: userDetails},
      })
      updateFormstate(() => ({
        ...formstate,
        formtype: "confirmSignUp",
      }))
      mixpanel.track("Signed Up")
      mixpanel.identify(username)
      mixpanel.people.set("Signup", {
        "name": fullname,
        "$email": email,
        "$username": username,
      });
    })

  }

  async function confirmSignUp() {
    const {username, authcode, fullname, email, password} = formstate;

    if (!authcode) {
      setErrormsg("To sign up please enter your " +
        "authentication code from your email");
      return;
    }

    await Auth.confirmSignUp(username, authcode).then(_ =>
      Auth.signIn(username, password)).then(
      _ => history.push("/profile"),
    ).catch(err => setErrormsg(err))

    mixpanel.identify(username)
    mixpanel.track("Confirmed Sign Up")
    mixpanel.people.set({
      "$email": email,
      "Sign up date": new Date(),
      "$username": username,
      "fullname": fullname,
    });
  }

  async function signIn() {
    const {username, password} = formstate;

    if (!username) {
      setErrormsg("To sign up please enter a username");
      return;
    } else if (!password) {
      setErrormsg("To sign up please enter your password");
      return;
    }

    await Auth.signIn(username, password).then(
      _ => {
        updateFormstate(() => ({...formstate, formtype: "signedIn"}))
        mixpanel.identify(username)
        mixpanel.track("Signed in")
        mixpanel.people.set({
          "$username": username,
        });
      },
    )

  }

  function onChange(e) {
    e.persist();
    updateFormstate(() => ({...formstate, [e.target.name]: e.target.value}));
  }

  function navigate(loc) {
    updateFormstate(() => ({...formstate, formtype: loc}));
  }

  return (
    <div className="">
      {/* SIGN IN FORM */}
      {formtype === "signIn" && (
        <SignInForm errormsg={errorMessage} onChange={onChange} onClick={signIn}
                    onClick1={(e) => e.preventDefault()}
                    onClick2={() => navigate("signUp")}/>
      )}

      {/* REGISTER FORM */}
      {formtype === "signUp" && (
        <SignUpForm errormsg={errorMessage} onChange={onChange}
                    onClick={(e) => e.preventDefault()}
                    onClick1={signUp} onClick2={() => navigate("signIn")}/>
      )}

      {/* CONFIRM SIGN IN  */}
      {formtype === "confirmSignUp" && (
        <ConfirmSignUpForm onChange={onChange} onClick={confirmSignUp}
                           onClick1={(e) => e.preventDefault()}
                           onClick2={() => navigate("signUp")}/>
      )}

      {formtype === "signedIn" && (<Redirect to={"/profile"}/>)}
    </div>
  );
}
