import React from "react";
import {Link} from "react-router-dom";
import CardBarChart from "../../../../../components/Cards/CardBarChart";
import CardStats from "../../../../../components/Cards/CardStats";
import {Mixpanel} from "../../../../../utils/marketing/Mixpanel";
import Hints from "../../QuestionProcessors/Hints";

export function answersQuestionUI(
  Header,
  questiono,
  practiceqs,
  choices,
  setChoices,
  handleQChange,
  setHide,
  hide,
  getAccuracies,
  setquestiono,
  totalTimes) {

  const getChoiceOption = () => {
    try {
      let chosenAnswerValue = practiceqs[questiono].options[choices[questiono]]
      return chosenAnswerValue
    } catch (err) {
      return null
    }
  }

  Mixpanel.track("Completed Test", {"end_time": new Date()})

  let selectedAnswer = getChoiceOption();

  return (
    <div className="h-screen w-fullscreen">
      <Header/>
      <div className="flex flex-row">
        <div className="w-full bg-white md:w-2/3 lg:w-2/3 xl:w-2/3">
          <div className="m-8 h-full">
            {questiono <= practiceqs.length - 1 ? (
              <img
                className={"mx-auto my-auto"}
                src={require(`questionEngine/parse_papers/question_imgs/${practiceqs[questiono].picture.toUpperCase()}`)}
                alt={"question graph"}/>
            ) : (
              <AnalyticsDisplay totalTimes={totalTimes}
                                getAccuracies={getAccuracies}/>)
            }
          </div>
        </div>

        <div
          className="bg-gray-200 w-full md:w-1/4 lg:w-1/3 xl:w-1/3 p-8 shadow-xl
           overflow-hidden">
          <div
            className="container px-12 mx-auto flex flex-wrap flex-col
            items-center justify-between">
            <div
              className="w-full relative flex flex-col hover:justify-between
              lg:w-auto lg:static lg:block lg:justify-start space-between
              align-middle items-center">
              {questiono <= practiceqs.length - 1 ? (
                <div>
                  <br/>
                  {practiceqs[questiono].question}
                  <div className="">
                    <div className="grid gap-4 py-4">
                      {MCQOptionAnswer("a", choices, questiono,
                        practiceqs)}
                      {MCQOptionAnswer("b", choices, questiono,
                        practiceqs)}
                      {MCQOptionAnswer("c", choices, questiono,
                        practiceqs)}
                      {MCQOptionAnswer("d", choices, questiono,
                        practiceqs)}
                    </div>
                  </div>

                  <div className="flex flex-end">
                    <div>
                      <button
                        className="text-white font-bold px-6 py-4 rounded
                       outline-none focus:outline-none mr-1 mb-1
                       bg-casemath-blue active:bg-blue-600 uppercase
                       text-sm shadow hover:shadow-lg ease-linear
                       transition-all duration-150"
                        onClick={() => {
                          handleQChange();
                          // generateNums();
                        }}
                      >
                        Next
                      </button>
                      <button
                        className="text-white font-bold px-6 py-4 rounded
                       outline-none focus:outline-none mr-1 mb-1 bg-pink-400
                       active:bg-pink-200 uppercase text-sm shadow
                       hover:shadow-lg ease-linear transition-all duration-150"
                        onClick={() => setHide(false)}
                      >
                        Solution
                      </button>
                    </div>
                  </div>
                  <br/>
                  <br/>
                  <div className={"text-center"}>

                    <h6 className="font-bold text-xl">
                      {selectedAnswer ? (<div>Your answer
                        was {choices[questiono].toUpperCase()}
                        <br/>
                        <span
                          className={"text-casemath-blue"}>{selectedAnswer}
                        </span>
                      </div>) : (<div>You did not answer this question</div>)}
                    </h6>

                  </div>

                  {hide ? null : (
                    <Hints
                      sethide={setHide}
                      hints={practiceqs[questiono].hints}/>
                  )}
                </div>
              ) : (
                <>
                  <div>
                    <p className="text-casemath-blue text-xl text-center">
                      Your accuracy: <span>{getAccuracies()}</span>
                    </p>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <div>
                    <p>
                      Check your answers along the top. Questions in green were
                      correctly answered and questions in red were incorrectly
                      answered. Click on the question and then "Solution"
                      to see how to complete the question.
                    </p>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <Link to="/practice">
                    <div
                      className="text-white font-bold px-6 py-4 rounded
                      outline-none focus:outline-none mr-1 mb-1 bg-pink-400
                      active:bg-blue-600 uppercase text-sm shadow
                      hover:shadow-lg ease-linear transition-all duration-150"
                    >
                      Back to topics
                    </div>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MCQOptionAnswer(letter, choices, questiono, practiceqs) {
  const evaluateBorderColor = () => {
    if (choices[questiono] === letter) {
      if (practiceqs[questiono].answer === letter) {
        return "border-green-500"
      } else {
        return "border-red-500"
      }
    } else if (letter === practiceqs[questiono].answer) {
      return "border-green-500"
    } else {
      return ""
    }
  }
  let borderColor = evaluateBorderColor();
  return (
    <div>
      {
        <button
          className={`shadow-sm focus:outline-none ease-in-out 
        transition-all duration-150 rounded-lg bg-white border-4 px-3 flex
        w-full py-4 my-2 space-x-4 grid grid-cols-2 ${borderColor}`}
          disabled={true}>
        <span className="flex text-left flex-grow capitalize">
            <span className="capitalize font-bold">{letter}</span>
        </span>
          <span className="text-right flex-grow">
          {practiceqs[questiono].options[letter]}
        </span>
        </button>
      }
    </div>
  )
}

function AnalyticsDisplay(props) {
  let labels = Object.keys(props.totalTimes)
  let timeVals = [{
    label: "Question",
    fill: false,
    backgroundColor: "#4c51bf",
    borderColor: "#4c51bf",
    data: Object.values(props.totalTimes),
    barThickness: 8,
  }]
  let title = "Time taken per question"
  let ytitle = "Time (ms)"
  let xtitle = "Question number"

  let totalTime = Math.round(Object.values(props.totalTimes)
    .reduce((a, b) => a + b, 0) / 1000)

  return (
    <div className={"flex flex-col"}>
      <div className={"grid gap-4 py-4 grid-cols-2"}>
        <CardStats
          statSubtitle="TOTAL TIME"
          statTitle={`${totalTime}s`}
          statArrow="up"
          statPercent={""}
          statPercentColor="text-green-500"
          statIconName="fas fa-hourglass-half"
          statIconColor="bg-blue-500"
        />
        <CardStats
          statSubtitle="ACCURACY"
          statTitle={`${props.getAccuracies()}`}
          statArrow="up"
          statPercent={""}
          statPercentColor="text-green-500"
          statIconName="fas fa-percent"
          statIconColor="bg-green-500"
        />
      </div>
      <CardBarChart
        labels={labels}
        title={title}
        ytitle={ytitle}
        datasets={timeVals}
        xtitle={xtitle}
      />
    </div>
  )
}