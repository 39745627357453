import React from "react";

/* -------------------------------------------------------------------------- */
/*                   Three column points about why choose us                  */
/* -------------------------------------------------------------------------- */

export default function ThreePointSell() {
  return (
    <div>
      <div className="container mx-auto py-10 ">
        <div className="text-center items-center">
          <h3 className="text-5xl mb-2 mt-2 font-bold leading-normal text-casemath-blue">
            We help you succeed
          </h3>
        </div>

        <div className="flex flex-wrap items-top text-center">
          <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-12">
            <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i className="fas fa-layer-group text-2xl text-casemath-blue"></i>
            </div>
            <h5 className="text-3xl mb-2 mt-2 font-semibold leading-normal">
              Reflect
            </h5>
            <div className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
              Understand your strengths and weaknesses. Focus on the skills <span className="font-bold">you</span> need.
            </div>
          </div>

          <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-12">
            <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i className="fas fa-comments text-2xl text-casemath-blue"></i>
            </div>
            
            <h5 className="text-3xl mb-2 mt-2 font-semibold leading-normal">
              Develop
            </h5>
            <div className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
              Learn exactly what you need to know from successful applicants.
            </div>
          </div>

          <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-12">
            <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i className="fas fa-users text-2xl text-casemath-blue"></i>
            </div>
            <h5 className="text-3xl mb-2 mt-2 font-semibold leading-normal">
              Assess
            </h5>
            <div className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
              Monitor your progress through mock tests and interviews and get personalised feedback. 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
