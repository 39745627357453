import {Auth as AuthenticationAWS} from "aws-amplify"
import FooterSmall from "components/Footers/FooterSmall.js";

// components
import Navbar from "components/Navbars/AuthNavbar.js";
import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";

// views
import Login from "views/auth/Login.js";

/* -------------------------------------------------------------------------- */
/*                         Authentication flow routing                        */
/* -------------------------------------------------------------------------- */

export default function Auth() {
  const history = useHistory()
  const [user, setUser] = useState(null);

  useEffect(() => {
    AuthenticationAWS.currentAuthenticatedUser().then(u => setUser(u))
  }, [])
  return (
    <>
      <Navbar transparent/>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png") + ")",
            }}
          />
          <Switch>
            <Route path="/auth/form/:authmode" exact component={Login}/>
            {/*<Redirect exact from="/auth" to="/auth/form/login"/>*/}
          </Switch>
          <FooterSmall absolute/>
        </section>
      </main>
    </>
  );
}
