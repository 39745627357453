import React from "react";
import { Editor } from "@tinymce/tinymce-react";

class ContentCreator extends React.Component {
  handleEditorChange = (content, editor) => {
    console.log("Content was updated:", content);
  };

  render() {
    return (
      <Editor
        initialValue="<p>Write a lesson for a topic on Casemath!</p>"
        init={{
          height: 600,
          menubar: true,
          selector: "textarea",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount latex",
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
        }}
        onEditorChange={this.handleEditorChange}
      />
    );
  }
}

export default ContentCreator;
