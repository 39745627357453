// Amplify
import {Auth} from "aws-amplify";
import React, {useEffect} from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";

// views
import Brainteasers from "views/practice/Brainteasers/BrainteasersMathsPage";
import TestTopicsPage from "views/practice/components/Topics/TestTopicsPage";
import TopicsPage from "views/practice/components/Topics/TopicsPage";
import MentalMathQs
  from "views/practice/MentalMaths/MentalMathsPage/MentalMaths"
import StandaloneQuestionsUI
  from "views/practice/PracticeQuestions/StandaloneQuestionsUI"
import PracticePage from "views/practice/Tests/PracticePage";
import * as Links from "./Links";

/* -------------------------------------------------------------------------- */
/*                             Admin page routing                             */

/* -------------------------------------------------------------------------- */

function Content() {
  const history = useHistory()

  useEffect(() => {
    Auth.currentAuthenticatedUser().then().catch(_ => history.replace(Links.AuthLogin))
  }, [])

  return (
    <>
      <Switch>
        <Route path="/practice/practiceqs" exact component={PracticePage}/>
        <Route path="/practice/topics/tests" exact component={TestTopicsPage}/>
        <Route
          path="/practice/topics/tests/aptitudetest/:testno"
          exact
          component={PracticePage}
        />

        {/* TODO: Reduce into APIfy? */}
        <Route
          path="/practice/topics/brainteasers"
          exact
          component={Brainteasers}
        />
        <Route
          path="/practice/topics/mental-math"
          exact
          component={MentalMathQs}
        />

        <Route
          path="/practice/topics/general"
          exact
          component={StandaloneQuestionsUI}
        />
        <Route path="/practice/topics" exact component={TopicsPage}/>
        <Redirect from="/practice" to="/practice/topics"/>
      </Switch>
    </>
  );
}

export default Content;
