import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import {Mixpanel} from "../../utils/marketing/Mixpanel";
import HorizontalLabelPositionBelowStepper from "./components/BookingForm";
import Pricing from "./components/Pricing";

export default function Booking() {

  Mixpanel.track("Entered Booking Page")

  return (
    <>
      <div className="bg-gradient-to-br from-white to-gray-100">
        <IndexNavbar fixed/>
        <div
          className="container flex flex-col text-center items-center mx-auto my-8">
          {/* <h1 className="text-4xl text-casemath-blue font-semibold">
           Book Face-to-Face Group Tutoring Sessions
           </h1>
           <p className="text-gray-800 text-md">
           Take a number of courses from our top tutors to prepare yourself for
           your dream role.
           </p> */}
        </div>
        <div className="my-4 mb-16">
          <Pricing/>
        </div>
        <div
          className="container flex flex-col text-center items-center mx-auto my-8">
          <div className="my-4">
            <h1 className="text-4xl font-semibold text-casemath-blue">
              Book Here
            </h1>
            <div className="my-4">
              <HorizontalLabelPositionBelowStepper/>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
}
