import {Container, CssBaseline, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
import {Mixpanel} from "../../utils/marketing/Mixpanel";
import ProfileForm from "./ProfilePage/components/ProfileForm";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
}));

export default function ProfilePage() {
  const classes = useStyles();

  Mixpanel.track("Entered Profile Page")

  return (
    <div>
      <IndexNavbar fixed/>
      <main>
        <div className={classes.heroContent}>
          <CssBaseline/>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="primary"
              gutterBottom
            >
              User Profile
            </Typography>
          </Container>
        </div>
      </main>
      <div className={classes.heroContent}>
        <Grid container>
          <Grid item xs={8}>
            <div className="p-8 rounded-xl bg-gray-200 shadow-xl mx-8">
              <ProfileForm/>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography
              component="h4"
              variant="h4"
              align="center"
              color="primary"
              gutterBottom
            >
              Analytics Coming Soon!
            </Typography>
            {/* <CardStats
             statSubtitle="ACCURACY"
             statTitle="99"
             statArrow="up"
             statPercent="3.48"
             statPercentColor="text-green-500"
             statDescripiron="Since last month"
             statIconName="far fa-chart-bar"
             statIconColor="bg-green-500"
             />
             <br />
             <CardStats
             statSubtitle="TRAFFIC"
             statTitle="350,897"
             statArrow="down"
             statPercent="3.48"
             statPercentColor="text-green-500"
             statDescripiron="Since last month"
             statIconName="far fa-chart-bar"
             statIconColor="bg-red-500"
             />
             <br />
             <CardStats
             statSubtitle="TRAFFIC"
             statTitle="350,897"
             statArrow="down"
             statPercent="3.48"
             statPercentColor="text-green-500"
             statDescripiron="Since last month"
             statIconName="far fa-chart-bar"
             statIconColor="bg-red-500"
             /> */}
          </Grid>
        </Grid>
      </div>

      <Footer/>
    </div>
  )
}
