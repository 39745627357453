import React from "react";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";


export function beginQuestionUI(handleView, props) {
  const {minutes, seconds} = props;
  return (
    <div>
      <div className="">
        <nav
          className="top-0 z-50 w-full flex flex-wrap items-center
          justify-between px-2 py-1 navbar-expand-lg bg-gray-800 shadow
          text-white">
          <div
            className="container px-12 mx-auto flex flex-wrap items-center
            justify-between">
            <div
              className="w-full relative flex justify-between lg:w-auto
              lg:static lg:block lg:justify-start">
              <div
                className="text-xl font-bold leading-relaxed
                inline-block mr-4 py-2 whitespace-no-wrap uppercase
                text-casemath-blue">
                PRACTICE TEST
              </div>
            </div>
          </div>
        </nav>

        <div className="flex flex-row">
          <div className="w-full bg-white md:w-2/3 lg:w-2/3 xl:w-2/3">
            <div className="m-8 h-full">
              <h1
                className="text-casemath-blue text-4xl font-semibold text-center
                 justify-center items-center overflow-hidden">
                Ready to begin?
              </h1>
            </div>
          </div>

          <div
            className="bg-gray-200 w-full md:w-1/4 lg:w-1/3 xl:w-1/3 p-8
            shadow-xl overflow-hidden">
            <div
              className="container px-12 mx-auto flex flex-wrap flex-col
              items-center justify-between">
              <div
                className="w-full relative flex flex-col hover:justify-between
                lg:w-auto lg:static lg:block lg:justify-start space-between
                align-middle items-center text-black text-semibold">
                <br/>
                <span className="font-bold text-casemath-blue">
                  Before you begin the test, please read the following:
                </span>
                <br/>
                <br/>
                <ol>
                  <li>
                    1. For optimal formatting, view this page on a computer.
                  </li>
                  <br/>
                  <li>
                    2. This test is multiple choice. The data will appear on
                    the left and the questions and answers will appear on the right.
                  </li>
                  <br/>
                  <li>
                    3. You can revisit any question at any time during the test
                    using the navigation bar on the top.
                  </li>
                  <br/>
                  <li>
                    4. The timer will be on the top-right - keep an eye on it.
                  </li>
                  <br/>
                  <li className={"text-casemath-blue text-semibold"}>
                    You will have {minutes} minutes and {seconds} seconds to
                    complete this test
                  </li>
                  <br/>
                </ol>
                <span className="font-bold text-casemath-blue">
                  Click the button below to start. Good luck!
                </span>
              </div>
              <br/>
              <button
                type="submit"
                onClick={() => {
                  handleView("questions");
                }}
                className="text-white font-bold px-6 py-4 rounded outline-none
                focus:outline-none mr-1 mb-1 bg-pink-400 active:bg-pink-200
                 uppercase text-sm shadow hover:shadow-lg ease-linear
                 transition-all duration-150"
              >
                Begin
              </button>
              <Grid item>
                <Link to="/practice">
                  <Button
                    variant={"outlined"}
                    color={"info"} 
                  >
                    Back to topics
                  </Button>
                </Link>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
